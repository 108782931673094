import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../helper/axios";
import { UserContext } from "./UserContext";

const logoutApi = axiosInstance;
function Header(props) {
  const { user, setUser, logOut } = useContext(UserContext);
  const [showDp, setShowDp] = useState(false);
  const [notiCount, setNotiCount] = useState("0");
  const [annCount, setAnnCount] = useState("0");
  const showhideDp = () => {
    setShowDp(!showDp);
  };
  useEffect(() => {
    if (user !== null) {
      getNotificationCount();
    }
  }, [user]);

  const getNotificationCount = () => {
    logoutApi.get("/notificationcount").then((res) => {
      if (res.status === 200) {
        setNotiCount(res.data.N);
        setAnnCount(res.data.A);
      }
    });
  };
  const logout = () => {
    logoutApi
      .post("/logout")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.loggedin === true) {
            window.localStorage.setItem(
              "user",
              JSON.stringify(res.data.params)
            );
            setUser(res.data.params);
          } else {
            logOut();
          }
        }
      })
      .catch((err) => {
        logOut();
      });
    setShowDp(false);
  };
  return (
    <>
      {user ? (
        <div className="main-header">
          <div className="logo" >
            <img src="/imgs/logo.png" alt="LOGO" />
          </div>

          <div
            className="menu-toggle"
            onClick={() => {
              props.setSideBarOpen(!props.sideBarOpen);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div className="logo">
            <img
              className=""
              src={user.logo}
              alt={user.uni_name}
              title={user.uni_name}
            />
          </div>

          <div className="m-auto"></div>
          <Link to="/videos">
            <i
              className="i-Video header-icon d-none d-sm-inline-block mobile-hide "
              title="Tutorial"
            ></i>
          </Link>

          <Link
            className="badge-top-container"
            role="button"
            title="Notifications"
            to="/notification"
          >
            {notiCount === "0" ? (
              <></>
            ) : (
              <span className="badge badge-danger" id="m_noti_4">
                {notiCount}
              </span>
            )}
            <i className="i-Bell text-muted header-icon"></i>
          </Link>
          <Link
            className="badge-top-container"
            role="button"
            title="Announcements"
            to="/announcement"
          >
            {annCount === "0" ? (
              <></>
            ) : (
              <span className="badge badge-danger" id="m_noti_4">
                {annCount}
              </span>
            )}
            <i className="i-Signal text-muted header-icon"></i>
          </Link>
          <div className="header-part-right">
            <div className="dropdown">
              <div
                className={
                  showDp
                    ? "user col align-self-end show"
                    : "user col align-self-end"
                }
              >
                <img
                  src={user.dp}
                  id="userDropdown"
                  alt=""
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={showhideDp}
                />
                <div
                  className={
                    showDp
                      ? "dropdown-menu dropdown-menu-right show"
                      : "dropdown-menu dropdown-menu-right"
                  }
                  aria-labelledby="userDropdown"
                >
                  <div className="dropdown-header">
                    <i className="i-Lock-User mr-1"></i> {user.name}
                  </div>
                  <Link
                    className="dropdown-item"
                    to="/profile"
                    onClick={showhideDp}
                  >
                    Profile
                  </Link>
                  <a href="/#" className="dropdown-item" onClick={logout}>
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
