import React from "react";

function PreviewQuiz(props) {
  let d = props.data;
  let ansContent;
  if (d.type === "T") {
    ansContent = (
      <textarea
        className="form-control"
        maxLength="900"
        rows="2"
        value={props.ansData["answer-" + d.id] || ""}
        readOnly={true}
      />
    );
  } else if (d.type === "R") {
    ansContent = (
      <div className="form-group">
        <div className="form-group field-surveydatadtl-1971-answer">
          <div id={"answer-" + d.id} role="radiogroup">
            {Object.keys(d.choices).map(function (key2) {
              let choice = d.choices[key2];
              return (
                <label
                  key={key2}
                  className="radio radio-primary mr-3 mb-3"
                  style={{ display: "block" }}
                >
                  <input
                    type="radio"
                    checked={choice === props.ansData["answer-" + d.id]}
                    readOnly={true}
                  />
                  <span>{choice}</span>
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (d.type === "M") {
    ansContent = (
      <div className="form-group">
        <div className="form-group field-surveydatadtl-1971-answer">
          <div id={"answer-" + d.id} role="radiogroup">
            {Object.keys(d.choices).map(function (key2) {
              let choice = d.choices[key2];
              let isChecked = false;

              if (props.ansData["answer-" + d.id]) {
                let ansr = Object.assign([], props.ansData["answer-" + d.id]);
                if (ansr.includes(choice)) {
                  isChecked = true;
                }
              }

              return (
                <label
                  key={key2}
                  className="checkbox radio-primary mr-3 mb-3"
                  style={{ display: "block" }}
                >
                  <input
                    type="checkbox"
                    value={choice}
                    checked={isChecked}
                    readOnly={true}
                  />
                  <span>{choice}</span>
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (d.type === "U") {
    ansContent = (
      <>
        {d.ans_img === null || d.ans_img === "" ? (
          <></>
        ) : (
          <>
            <img src={d.ans_img} alt="" className="img-responsive mb-2" />
          </>
        )}

        <textarea
          className="form-control"
          maxLength="900"
          rows="2"
          value={props.ansData["answer-" + d.id] || ""}
        />
      </>
    );
  }

  return (
    <div>
      <h4 style={{ color: "black" }}>
        Q{props.qid + 1}. {props.data.question}
        <button
          className="btn btn-primary pull-right mb-2"
          onClick={() => {
            props.setCurrentQid(props.qid )
            window.scrollTo(0, 0)
            props.setReview(false);
          }}
        >
          Update
        </button>
      </h4>

      {d.q_img === "" ? (
        <></>
      ) : (
        <img src={d.q_img} alt="Question" className="img-responsive mb-2" />
      )}
      <div>
        {ansContent}
        <hr />
      </div>
    </div>
  );
}

export default PreviewQuiz;
