import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-dropdown/style.css";
import { useSearchParams } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import PreviewQuiz from "../../components/PreviewQuiz";
import Question from "../../components/Question";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Quiz(props) {
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const quizId = searchParams.get("id");

  const [loading, setLoading] = useState(false);
  const [fill, setFill] = useState(false);
  const [review, setReview] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [surveyData, setSurveyData] = useState({
    activity: { activity_name: "Online Quiz", class_name: "", notes: "" },
    quizDetail: [],
  });

  const [ansData, setAnsData] = useState({});
  const [errorArr, setErrorArr] = useState({});
  const [toTime, setToTime] = useState("");
  const [currentQid, setCurrentQid] = useState(-1);

  const calculateTimeLeft = useMemo(
    () => () => {
      let difference = 0;

      if (toTime !== "") {
        difference = +new Date(toTime) - +new Date();
      }
      let timeLeft = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          mins: Math.floor((difference / 1000 / 60) % 60),
          secs: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    },
    [toTime]
  );

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function submitQuiz(e) {
    e.preventDefault();

    attApi
      .post("/quizsubmit?id=" + quizId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.sucess === true) {
            notify(res.data.message, "success");
            navigate("/");
          } else {
            setErrorTxt(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorTxt("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorTxt(err.response.data.message);
        } else {
          setErrorTxt(err.message);
        }
      });
  }
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  }, [calculateTimeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, index) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={index} className="mr-1 text-20">
        {timeLeft[interval]} {interval}
      </span>
    );
  });

  const handleImageChange = (event) => {
    //  let reader = new FileReader();
    const files = event.target.files;

    if (files.length) {
      const file = files[0];
      setAnsData({ ...ansData, [event.target.id]: file });
      setErrorTxt("");
      setErrorArr({});
    }
  };

  function handleChange(event) {
    setAnsData({ ...ansData, [event.target.id]: event.target.value });
    setErrorTxt("");
    setErrorArr({});
  }

  function handleCheckChange(event) {
    let eVal = Object.assign([], ansData[event.target.id]);
    if (event.target.checked) {
      eVal.push(event.target.value);
    } else {
      eVal = eVal.filter(function (item) {
        return item !== event.target.value;
      });
    }
    setAnsData({ ...ansData, [event.target.id]: eVal });
    setErrorTxt("");
    setErrorArr({});
  }

  async function reloadQuiz() {
    attApi.get("/quizquestions?id=" + quizId).then((res) => {
      if (res.status === 200) {
        if (res.data.success === false) {
        } else {
          setSurveyData(res.data);
          setAnsData(res.data.answers);
        }
      }
    });
  }
  useEffect(() => {
    setLoading(true);
    setTitle("Quiz");
    setOperation("Quiz");
    setPLink("");
    if (quizId !== "") {
      attApi
        .get("/quizquestions?id=" + quizId)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.sucess === false) {
              setTitle(res.data.activity_name);
              setFill(false);
              setErrorTxt(res.data.message);
            } else {
              setTitle(res.data.activity.activity_name);
              setOperation(res.data.activity.activity_name);
              setSurveyData(res.data);
              setFill(true);
              setToTime(res.data.activity.to_time);
              if (res.data.quizDetail.length > 0) {
                setCurrentQid(0);
              }
              setAnsData(res.data.answers);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [setTitle, setOperation, setPLink, quizId]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <>
          {fill ? (
            <>
              <div className="card">
                <div className="card-body">
                  <i className="i-Clock mr-24" style={{ fontSize: "30px" }}></i>
                  {timerComponents.length ? (
                    timerComponents
                  ) : (
                    <span>Time's up!</span>
                  )}
                  <p>
                    {surveyData.activity.notes === ""
                      ? ""
                      : surveyData.activity.notes}
                  </p>
                  {surveyData.quizDetail.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      {review ? (
                        <>
                          {surveyData.quizDetail.map(function (d, index) {
                            return (
                              <PreviewQuiz
                                key={index}
                                setReview={setReview}
                                ansData={ansData}
                                setCurrentQid={setCurrentQid}
                                qid={index}
                                data={d}
                              />
                            );
                          })}
                          <p>
                            <button
                              className="btn btn-outline-success mr-2 mb-2"
                              onClick={submitQuiz}
                            >
                              Submit
                            </button>
                          </p>
                        </>
                      ) : (
                        <div className="row mb-3">
                          <div className="col-md-10 col-sm-12 mb-4">
                            {currentQid === -1 ? (
                              <></>
                            ) : (
                              <Question
                                setReview={setReview}
                                quizId={quizId}
                                total={surveyData.quizDetail.length}
                                data={surveyData.quizDetail[currentQid]}
                                qid={currentQid + 1}
                                index={currentQid}
                                setCurrentQid={setCurrentQid}
                                handleChange={handleChange}
                                handleCheckChange={handleCheckChange}
                                ansData={ansData}
                                errorArr={errorArr}
                                setErrorTxt={setErrorTxt}
                                setAnsData={setAnsData}
                                handleImageChange={handleImageChange}
                                reloadQuiz={reloadQuiz}
                                submitQuiz={submitQuiz}
                              />
                            )}
                          </div>
                          <div className="col-md-2 col-sm-12">
                            {surveyData.quizDetail.map(function (d, index) {
                              return (
                                <button
                                  key={index}
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    setCurrentQid(index);
                                  }}
                                  className={
                                    "btn btn-rounded mr-2 mb-2 " +
                                    (ansData["answer-" + d.id] === "" ||
                                    (ansData["answer-" + d.id] instanceof
                                      Array &&
                                      ansData["answer-" + d.id].length === 0)
                                      ? "btn-danger"
                                      : d.answer === ""
                                      ? "btn-warning"
                                      : "btn-success ")
                                  }
                                >
                                  {index + 1}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="required text-17">{errorTxt}</p>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Quiz;
