import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import CalanderEvents from "../../components/CalanderEvents";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataLoading from "../../components/DataLoading";

const attApi = axiosInstance;
const attApi2 = axiosInstance;

function TimeTable(props) {
  const { user, setTitle, setOperation, setPLink } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  let date2 = searchParams.get("date");

  if (!date2) {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    date2 = date + "-" + month + "-" + year;
  }

  const [cDate, setCDate] = useState(date2);
  const [cDates, setCDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [nextDate, setNextDate] = useState("");
  const [previousDate, setPreviousDate] = useState("");
  var from = date2.split("-");
  var calDate = new Date(from[2], from[1] - 1, from[0]);
  const [startDate, setStartDate] = useState(calDate);

  function setCalDate(date) {
    setStartDate(date);

    const dt = new Date(Date.parse(date));
    var da = dt.getDate();
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
    setCDate(da + "-" + month + "-" + year);
  }
  useEffect(() => {
    setLoading(true);
    setTitle("Time Table");
    setOperation("Time Table");
    setPLink("");
    attApi.get("/timeslots?date=" + cDate).then((res) => {
      
      if (res.status === 200) {
        setFromDate(res.data.fromDate);
        setToDate(res.data.toDate);
        setCDates(res.data.dates);
        setTimeSlots(res.data.timeSlots);
        setNextDate(res.data.nextDate);
        setPreviousDate(res.data.previousDate);
        attApi2
          .get("/timetable?date=" + cDate)
          .then((res) => {
            if (res.status === 200) {
              setTimeData(res.data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }, [user, setTitle, setOperation, setPLink, cDate]);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <div className="card">
          <div className="card-body">
            <div className="col-md-6  mt-12" style={{ fontSize: "20px" }}>
              {fromDate} - {toDate}
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 mt-12 ">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => {
                      setCalDate(date);
                    }}
                    dateFormat="dd-MM-yyyy"
                  />
                  <Link
                    to={"/timetable?date=" + previousDate}
                    onClick={() => {
                      setCDate(previousDate);
                    }}
                    title="Previous Week"
                  >
                    <i
                      className="glyphicon glyphicon-circle-arrow-left  mr-12"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </Link>
                  <Link
                    to={"/timetable?date=" + nextDate}
                    onClick={() => {
                      setCDate(nextDate);
                    }}
                    title="Next Week"
                  >
                    <i
                      className="glyphicon glyphicon glyphicon-circle-arrow-right"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <table className="table table-bordered table-cal  table-responsive mt-4">
                <thead>
                  <tr>
                    <th></th>
                    {Object.keys(cDates).map(function (key) {
                      return (
                        <th key={key} className="daynumber">
                          {cDates[key].showDate}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(timeSlots).map(function (key) {
                    let slotId = timeSlots[key].id;
                    let slotName = timeSlots[key].name;
                    return (
                      <tr key={key}>
                        <td>
                          <strong>{timeSlots[key].name}</strong>
                          <br />
                          {timeSlots[key].from_time} - {timeSlots[key].to_time}
                        </td>
                        {Object.keys(cDates).map(function (key2) {
                          let dd = cDates[key2].dDate;
                          let sd = cDates[key2].hdate;
                          let show = null;

                          if (timeData.hasOwnProperty(dd)) {
                            show = timeData[dd];
                          }

                          return (
                            <td key={key2}>
                              {show ? (
                                <CalanderEvents
                                  data={show}
                                  slotId={slotId}
                                  slotName={slotName}
                                  showDate={sd}
                                ></CalanderEvents>
                              ) : (
                                <></>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TimeTable;
