import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px",
  },
};
Modal.setAppElement("#root");

const profileApi = axiosInstance;
const peoApi = axiosInstance;
const ploApi = axiosInstance;
function Profile(props) {
  const [loading, setLoading] = useState(false);
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);
  const [profileData, setProfileData] = useState([]);
  const [peoData, setPeoData] = useState([]);
  const [ploData, setPloData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isOBE, setIsOBE] = useState(false);
  const [programName, setProgramName] = useState("Program");
  const [batchName, setBatchName] = useState("Program Batch");
  const [peoName, setPeoName] = useState("PEOs");
  const [ploName, setPloName] = useState("PLOs");
  const [docTab, setdocTab] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [filePath, setFilePath] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [docData, setDocData] = useState([]);
  const [docFiles, setDocFiles] = useState({});

  function setPreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  }
  function setDocFile(e, aid) {
    if (e.target.files && e.target.files.length > 0) {
      setDocFiles({ ...docFiles, [aid]: e.target.files[0] });
      console.log(docFiles);
    }
  }

  function updateDocs(e) {
    e.preventDefault();
    var formData = new FormData();
    Object.keys(docFiles).map(function (key) {
      return formData.append("Attachment["+key+"][file]", docFiles[key]);
    });
    
    profileApi
      .post("/uploaddocs", formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            getDocData()
          } else {
            return notify(res.data.message, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return notify("Invalid arugument sent", "error");
        } else if (err.response.status === 403) {
          return notify(err.response.data.message, "error");
        } else {
          return notify(err.message, "error");
        }
      });
  }

  function getDocData() {
    setLoading(true);
    profileApi
      .get("/getdocs")
      .then((res) => {
        if (res.status === 200) {
          setDocData(res.data);
          console.log(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function updateCV(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("file", selectedFile);
    profileApi
      .post("/uploadresume", formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            getData();
            closeModal();
          } else {
            return notify(res.data.message, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return notify("Invalid arugument sent", "error");
        } else if (err.response.status === 403) {
          return notify(err.response.data.message, "error");
        } else {
          return notify(err.message, "error");
        }
      });
  }
  function openModal() {
    setFilePath(profileData.cv);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const columns = [
    {
      name: "Code",
      sortable: true,
      accessor: "Code",
      selector: (row) => row.Code,
      width: "100px",
    },
    {
      name: "Description",
      accessor: "Description",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.Description}</div>
      ),
      sortable: true,
    },
  ];
  function tabClicked(e, id) {
    e.preventDefault();
    setSelectedTab(id);
    if (id === 4) {
      getDocData();
    }
  }

  const getData = () => {
    setLoading(true);
    profileApi
      .get("/profile")
      .then((res) => {
        if (res.status === 200) {
          setProfileData(res.data);
          setPeoName(res.data.peoName);
          setPloName(res.data.ploName);
          setProgramName(res.data.programName);
          setBatchName(res.data.batchName);
          setIsOBE(res.data.isOBE);
          setdocTab(res.data.docTab);
          if (res.data.isOBE === true) {
            peoApi.get("/peolist").then((res) => {
              if (res.status === 200) {
                setPeoData(res.data);
              }
            });
            ploApi.get("/plolist").then((res) => {
              if (res.status === 200) {
                setPloData(res.data);
              }
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setTitle("Profile");
    setOperation("Profile");
    setPLink("");
    getData();
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <table className="mb-12">
            <tbody>
              <tr>
                <td>
                  <img
                    className="avatar-sm rounded-circle mr-24"
                    src={profileData.dp}
                    alt="DP"
                  />
                </td>
                <td>
                  <h4 className="d-inline">
                    {profileData.reg_no} - {profileData.name}
                  </h4>
                  <br />
                  <p className="d-inline">{profileData.program}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ul className="nav nav-tabs" id="myIconTab" role="tablist">
            <li className="nav-item">
              <a
                className={selectedTab === 1 ? "nav-link active" : "nav-link "}
                id="home-icon-tab"
                data-toggle="tab"
                href="/#"
                role="tab"
                aria-controls="homeIcon"
                aria-selected="true"
                onClick={(event) => {
                  tabClicked(event, 1);
                }}
              >
                View
              </a>
            </li>

            {isOBE ? (
              <li className="nav-item">
                <a
                  className={
                    selectedTab === 2 ? "nav-link active" : "nav-link "
                  }
                  id="profile-icon-tab"
                  data-toggle="tab"
                  href="/#"
                  role="tab"
                  onClick={(event) => {
                    tabClicked(event, 2);
                  }}
                >
                  {programName} {peoName}
                </a>
              </li>
            ) : (
              <></>
            )}
            {isOBE ? (
              <li className="nav-item">
                <a
                  className={
                    selectedTab === 3 ? "nav-link active" : "nav-link "
                  }
                  id="contact-icon-tab"
                  data-toggle="tab"
                  href="/#"
                  role="tab"
                  aria-controls="contactIcon"
                  aria-selected="false"
                  onClick={(event) => {
                    tabClicked(event, 3);
                  }}
                >
                  {programName} {ploName}
                </a>
              </li>
            ) : (
              <></>
            )}

            {docTab ? (
              <li className="nav-item">
                <a
                  className={
                    selectedTab === 4 ? "nav-link active" : "nav-link "
                  }
                  id="document-icon-tab"
                  data-toggle="tab"
                  href="/#"
                  role="tab"
                  aria-controls="contactIcon"
                  aria-selected="false"
                  onClick={(event) => {
                    tabClicked(event, 4);
                  }}
                >
                  Documents
                </a>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="card">
            <div className="tab-content" id="myIconTabContent">
              <div
                className={
                  selectedTab === 1 ? "tab-pane show active" : "tab-pane "
                }
                role="tabpanel"
                aria-labelledby="home-icon-tab"
              >
                <p>
                  <Link className="btn btn-primary mr-1" to="/updateprofile">
                    Update
                  </Link>
                  <Link
                    className="btn btn-primary mr-1"
                    to="/updateprofileimage"
                  >
                    Update Image
                  </Link>
                  <Link className="btn btn-primary mr-1" to="/changepassword">
                    Change Password
                  </Link>
                  <span
                    className="btn btn-primary"
                    value="/unistudent/updateresume"
                    onClick={openModal}
                  >
                    Résumé / CV
                  </span>
                </p>
                <p>{profileData.intro}</p>
                <div className="row">
                  <div className="col-md-4">
                    <table
                      id="w0"
                      className="table table-striped table-bordered detail-view"
                    >
                      <tbody>
                        <tr>
                          <th>Registration No.</th>
                          <td>{profileData.reg_no}</td>
                        </tr>
                        <tr>
                          <th>Roll No.</th>
                          <td>{profileData.roll_no}</td>
                        </tr>
                        <tr>
                          <th>{batchName}</th>
                          <td>{profileData.program}</td>
                        </tr>
                        <tr>
                          <th>Gender</th>
                          <td>{profileData.gender}</td>
                        </tr>
                        <tr>
                          <th>ERP ID</th>
                          <td>{profileData.erp_id}</td>
                        </tr>
                        <tr>
                          <th>Date of Birth</th>
                          <td>{profileData.dob}</td>
                        </tr>
                        <tr>
                          <th>Section</th>
                          <td>{profileData.section}</td>
                        </tr>
                        <tr>
                          <th>Is Local Resident</th>
                          <td>{profileData.local}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table
                      id="w1"
                      className="table table-striped table-bordered detail-view"
                    >
                      <tbody>
                        <tr>
                          <th>Father Name</th>
                          <td>{profileData.father}</td>
                        </tr>
                        <tr>
                          <th>Mother Name</th>
                          <td>{profileData.mother}</td>
                        </tr>
                        <tr>
                          <th>CNIC</th>
                          <td>{profileData.cnic}</td>
                        </tr>
                        <tr>
                          <th>Passport</th>
                          <td>{profileData.passport}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td> {profileData.email} </td>
                        </tr>
                        <tr>
                          <th>Mobile</th>
                          <td>{profileData.cell}</td>
                        </tr>
                        <tr>
                          <th>Home Phone</th>
                          <td>{profileData.home_phone}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>{profileData.staus}</td>
                        </tr>
                        <tr>
                          <th>Study Mode</th>
                          <td>{profileData.type}</td>
                        </tr>
                        <tr>
                          <th>Willing to Relocate?</th>
                          <td>{profileData.relocateV}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table
                      id="w2"
                      className="table table-striped table-bordered detail-view"
                    >
                      <tbody>
                        <tr>
                          <th>Religion</th>
                          <td>{profileData.religion}</td>
                        </tr>
                        <tr>
                          <th>Marital Status</th>
                          <td>{profileData.marital_status}</td>
                        </tr>
                        <tr>
                          <th>Work Experience (Years)</th>
                          <td>{profileData.experirence}</td>
                        </tr>
                        <tr>
                          <th>Employement Status</th>
                          <td>{profileData.emp_statusV}</td>
                        </tr>
                        <tr>
                          <th>Next of Kin Name</th>
                          <td>{profileData.next_kin}</td>
                        </tr>
                        <tr>
                          <th>Next of Kin Mobile</th>
                          <td>{profileData.next_kin_mobile}</td>
                        </tr>
                        <tr>
                          <th>Website</th>
                          <td>{profileData.website}</td>
                        </tr>
                        <tr>
                          <th>Facebook ID</th>
                          <td>{profileData.fb}</td>
                        </tr>
                        <tr>
                          <th>LinkedIn ID</th>
                          <td>{profileData.linkedin}</td>
                        </tr>
                        <tr>
                          <th>Twitter Handle</th>
                          <td>{profileData.tweeter}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <h5>
                  <u>Address</u>
                </h5>

                <div className="row">
                  <div className="col-md-6">
                    <table
                      id="w3"
                      className="table table-striped table-bordered detail-view"
                    >
                      <tbody>
                        <tr>
                          <th>Permanent Address</th>
                          <td>{profileData.address}</td>
                        </tr>
                        <tr>
                          <th>Current City</th>
                          <td>{profileData.city}</td>
                        </tr>
                        <tr>
                          <th>District</th>
                          <td>{profileData.district}</td>
                        </tr>
                        <tr>
                          <th>Province</th>
                          <td>{profileData.province}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table
                      id="w4"
                      className="table table-striped table-bordered detail-view"
                    >
                      <tbody>
                        <tr>
                          <th>Country</th>
                          <td>{profileData.country}</td>
                        </tr>
                        <tr>
                          <th>Postal Address</th>
                          <td>{profileData.postal_address}</td>
                        </tr>
                        <tr>
                          <th>Permit of Residency</th>
                          <td>{profileData.permit_residency}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className={
                  selectedTab === 2 ? "tab-pane show active" : "tab-pane "
                }
                id="profileIcon"
                role="tabpanel"
                aria-labelledby="profile-icon-tab"
              >
                <DataTable
                  columns={columns}
                  data={peoData}
                  highlightOnHover
                  striped
                />
              </div>
              <div
                className={
                  selectedTab === 3 ? "tab-pane show active" : "tab-pane "
                }
                id="contactIcon"
                role="tabpanel"
                aria-labelledby="contact-icon-tab"
              >
                <DataTable
                  columns={columns}
                  data={ploData}
                  highlightOnHover
                  striped
                />
              </div>
              <div
                className={
                  selectedTab === 4 ? "tab-pane show active" : "tab-pane "
                }
                id="documentIcon"
                role="tabpanel"
                aria-labelledby="document-icon-tab"
              >
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Document Type</th>
                      <th>Uploaded When</th>
                      <th style={{ textAlign: "center" }}>Verified</th>
                      <th>File</th>
                      <th>Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(docData).map(function (key) {
                      let d = docData[key];
                      return (
                        <tr key={key}>
                          <td>{d.aTypeName}</td>
                          <td>{d.cdate}</td>
                          <td style={{ textAlign: "center" }}>
                            {d.verified === "" ? (
                              ""
                            ) : d.verified === "1" ? (
                              <span className="glyphicon glyphicon-ok-sign text-success outcome-sign"></span>
                            ) : (
                              <span className="glyphicon glyphicon-remove-sign text-danger outcome-sign"></span>
                            )}
                          </td>
                          <td>
                            {" "}
                            {d.path === "" ? (
                              ""
                            ) : (
                              <a
                                href={d.path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View File
                              </a>
                            )}
                          </td>
                          <td>
                            <input
                              type="file"
                              name={"file"}
                              id={"file"}
                              onChange={(e) => {
                                setDocFile(e, d.aType);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <p>
                  1. Allowed file types are doc, docx, pdf, jpg, jpeg, and png.
                  <br />
                  2. Max upload size is 2MB
                </p>
                <div className="form-group mt-24">
                  <button
                    type="button"
                    className=" btn btn-success mt-3 mr-2"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={updateDocs}
                  >
                    Save
                  </button>
                </div>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <div className="modal-header">
                  <h4 id="myLargeModalLabel" className="modal-title">
                    Update Résumé / CV
                  </h4>
                </div>
                <div className="modal-body">
                  <input
                    type="file"
                    name={"file"}
                    id={"file"}
                    onChange={(e) => {
                      setPreview(e);
                    }}
                  />
                  {filePath === "" ? (
                    <></>
                  ) : (
                    <div className="mt-3">
                      <a
                        href={filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="glyphicon glyphicon-paperclip pr-2">
                          <span className="ml-2">Download Résumé / CV</span>
                        </span>
                      </a>
                    </div>
                  )}

                  <p className="mt-2">
                    Please upload only pdf, doc or docx file only
                    <br />
                    Max file allowed file size is 2MB
                  </p>

                  <button
                    type="button"
                    className=" btn btn-success mt-3 mr-2"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={updateCV}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className=" btn btn-danger mt-3"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Profile;
