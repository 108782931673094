import React from 'react';

function FilterComponent(props) {
    return (
        <div className="search-bar searchDiv ">
            <input value={props.filterText} onChange={props.onFilter}  type="text" placeholder="Search text" className="searchText" />
            <i className="search-icon text-muted i-Magnifi-Glass1"></i>
        </div>
        
    );
}
export default FilterComponent;