import React, { useCallback, useContext, useEffect, useState } from "react";

import {  useSearchParams } from "react-router-dom";
import ClassRoomHead from "../../components/ClassRoomHead";
import ClassRoomTopMenu from "../../components/ClassRoomTopMenu";
import { UserContext } from "../../components/UserContext";
import ClassTimeTable from "./ClassTimeTable";
import Forums from "./Forums";
import TeachingPlan from "./TeachingPlan";
import Attachments from "./Attachments";
import Media from "./Media";
import Clos from "./Clos";
import ClosAttainment from "./ClosAttainment";
import PlosAttainment from "./PlosAttainment";
import Cqi from "./Cqi";
import View from "./View";
import axiosInstance from "../../helper/axios";
import DataLoading from "../../components/DataLoading";

const attApi = axiosInstance;

function ClassRoom(props) {
  const [searchParams] = useSearchParams();
  const path = window.location.pathname;
  const cId = searchParams.get("id");

  const [classId, setClassId] = useState(0);
  const [currentTab, setCurrentTab] = useState(-1);
  const [subCurrentTab, setSubCurrentTab] = useState(-1);
  const [apiError, setApiError] = useState("");

  if (currentTab === -1) {
    let ct = 1;
    let sct = 1;
    if (path === "/classroom/timetable") {
      ct = 3;
      sct = 3;
    } else if (path === "/classroom/media") {
      ct = 2;
      sct = 2.1;
    } else if (path === "/classroom/classplan") {
      ct = 2;
      sct = 2.2;
    } else if (path === "/classroom/attachments") {
      ct = 2;
      sct = 2.3;
    } else if (path === "/classroom/clos") {
      ct = 4;
      sct = 4.1;
    } else if (path === "/classroom/cloattainment") {
      ct = 4;
      sct = 4.2;
    } else if (path === "/classroom/ploattainment") {
      ct = 4;
      sct = 4.3;
    } else if (path === "/classroom/cqi") {
      ct = 4;
      sct = 4.4;
    } else if (path === "/classroom/forums") {
      ct = 5;
      sct = 5;
    }
    setCurrentTab(ct);
    setSubCurrentTab(sct);
  }
  const [loading, setLoading] = useState(true);
  const { setTitle, setOperation, setPLink } = useContext(UserContext);
  useEffect(() => {
    setTitle("Course Section");
    setOperation("Course Section");
    setPLink([{ name: "My Courses", link: "/classrooms" }]);
    setClassId(cId);
  }, [setTitle, setOperation, setPLink, cId]);

  const [classData, setClassData] = useState({});
  const getHeadData = useCallback(async () => {
    setLoading(true);
    await attApi
      .get("/classhead?id=" + classId)
      .then((res) => {
        if (res.status === 200) {
          setTitle(res.data.className);
          setOperation(res.data.name);
          setClassData(res.data);
        }
      })
      .catch((err) => {
        setApiError(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, classId]);

  useEffect(() => {
    if (classId !== 0) {
      getHeadData();
    }
  }, [classId, getHeadData]);

  const content = () => {
    if (loading === false) {
      switch (subCurrentTab) {
        case 1:
          return <View classId={classId} />;
        case 3:
          return <ClassTimeTable classId={classId} />;
        case 5:
          return <Forums classId={classId} />;
        case 2.1:
          return <Media classId={classId} />;
        case 2.2:
          return (
            <TeachingPlan classId={classId} ploBase={classData.plo_base} />
          );
        case 2.3:
          return <Attachments classId={classId} />;
        case 4.1:
          return <Clos classId={classId} ploBase={classData.plo_base} />;
        case 4.2:
          return (
            <ClosAttainment classId={classId} perMarks={classData.per_marks} />
          );
        case 4.3:
          return (
            <PlosAttainment
              classId={classId}
              perMarks={classData.batch_per_marks}
            />
          );
        case 4.4:
          return <Cqi classId={classId} />;
        default:
          return <h1 className="mt-5">---unkown option selected---</h1>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <div>
      {classId === 0 ? (
        <></>
      ) : (
        <>
          {loading ? (
            <DataLoading />
          ) : (
            <>
              {apiError !== "" ? (
                <h4>Page {apiError.status} : {apiError.statusText}</h4>
              ) : (
                <>
                  <ClassRoomHead classId={classId} classData={classData} />

                  <ClassRoomTopMenu
                    current={currentTab}
                    subCurrentTab={subCurrentTab}
                    classId={classId}
                    setCurrentTab={setCurrentTab}
                    setSubCurrentTab={setSubCurrentTab}
                    closName={classData.closName}
                    plosName={classData.plosName}
                    obeLabel={classData.labelOBE}
                    showObe={classData.showOBE}
                    showTimeTable={classData.showTimeTable}
                    ploBase={classData.plo_base}
                  />

                  {loading ? (
                    <></>
                  ) : (
                    <div className="card">
                      <div className="card-body">{content()}</div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ClassRoom;
