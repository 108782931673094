import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function AdmitCard(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);

  function getAdmitCard(id) {
    const axiosConf = {
        responseType:'arraybuffer'
    }
    setLoading(true)
    attApi.get("/getadmitcard?id=" + id, axiosConf).then((response) => {
      if (response.status === 200) {
        let fileName = "Admit Card.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            }),
            fileName
          );
        } else {

          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    }).finally(() => {
        setLoading(false);
      });
  }
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const columns = [
    {
      name: "#",
      selector: (row, index, id) => index + 1,
      width: "40px",
    },
    {
      name: "Courses",
      sortable: true,
      accessor: "course",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">
          {row.course + " (" + row.class_name + ")"}
        </div>
      ),
    },
    {
      name: "Teacher/ Instructor",
      accessor: "teacher",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.teacher}</div>
      ),
      sortable: true,
    },
    {
      name: "Attendance",
      accessor: "attendance",
      selector: (row) => (
        <div className={row.attendance_complete ?"text-wrap mt-2 mb-2":"text-wrap mt-2 mb-2 required"}>{row.attendance}</div>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Survey",
      accessor: "survey",
      selector: (row) => (
        <div className= {row.survey_complete ?"text-wrap mt-2 mb-2":"text-wrap mt-2 mb-2 required"}>{row.survey}</div>
      ),
      sortable: true,
      width: "100px",
    },
  ];
  useEffect(() => {
    setLoading(true);
    setTitle("Admit Card");
    setOperation("Admit Card");
    setPLink("");
    attApi
      .get("/admitcard")
      .then((res) => {
        if (res.status === 200) {
          setCards(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink]);
  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <div>
          {cards.map(function (d, index) {
           
            return (
              <div className="card" key={index}>
                <div className="card-body">
                  <h4>{d.semester}</h4>
                  <p>{d.title}</p>

                  <DataTable
                    columns={columns}
                    data={d.classRooms}
                    fixedHeader
                    highlightOnHover
                    striped
                  />
                  {
                    d.enabled ?
                    <span className="btn btn-primary" role="button" onClick={()=>getAdmitCard(d.id)}>Download</span>
                    :<></>
                  }
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default AdmitCard;
