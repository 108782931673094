import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const profileApi = axiosInstance;
const changeApi = axiosInstance;

function ChangePassword(props) {
  const { setTitle, setOperation, setPLink, notify } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [values, setValues] = useState({ oldpass: "", pass1: "", pass2: "" });
  const [errorText, setErrorText] = useState("");
  const [profileData, setProfileData] = useState([]);

  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }

  function changePass(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("old_pass", values.oldpass);
    formData.append("pass1", values.pass1);
    formData.append("pass2", values.pass2);
    changeApi
      .post("/changepassword", formData)
      .then((res) => {
        if (res.status === 200) {
          navigate("/profile");
          if(res.data.success === true){
            notify(res.data.message , 'success')
          }else{
            let messages = res.data.message;
            messages.map(function (key) {
                return notify( key, 'error')
              });
            
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      });
  }
  useEffect(() => {
    setTitle("Change Password");
    setOperation("Change Password");
    setPLink([
      { name: "Profile", link: "/profile" },
    ]);
    profileApi.get("/profile").then((res) => {
      if (res.status === 200) {
        setProfileData(res.data);
      }
    });
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      <table className="mb-12">
        <tbody>
          <tr>
            <td>
              <img
                className="avatar-sm rounded-circle mr-24"
                src={profileData.dp}
                alt="DP"
              />
            </td>
            <td>
              <h4 className="d-inline">
                {profileData.reg_no} - {profileData.name}
              </h4>
              <br />
              <p className="d-inline">{profileData.program}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group field-studentuser-oldpass required">
                  <label className="control-label" htmlFor="oldpass">
                    Old Password
                  </label>
                  <input
                    type="password"
                    id="oldpass"
                    className="form-control"
                    name="oldpass"
                    value={values.oldpass}
                    aria-required="true"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group field-studentuser-pass1 required">
                  <label className="control-label" htmlFor="pass1">
                    Password
                  </label>
                  <input
                    type="password"
                    id="pass1"
                    className="form-control"
                    name="pass1"
                    value={values.pass1}
                    aria-required="true"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group field-studentuser-pass2 required">
                  <label className="control-label" htmlFor="pass2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="pass2"
                    className="form-control"
                    name="pass2"
                    value={values.pass2}
                    aria-required="true"
                    onChange={handleChange}
                  />

                  <div className="help-block required">{errorText}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <button onClick={changePass} className="btn btn-primary">
                    Change
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
