import React, { useContext  } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";

function Footer(props) {
  const { user } = useContext(UserContext);
  return (
    <>
      {user ? (
        <>
        <div className="app-footer">
          <div className="row">
            <div className="col-md-4 mb-12">
              <h4>About QOBE</h4>
              <p className="text-justify">
                QOBE is most widely used Outcome Based LMS in region. QOBE
                offers you the framework and necessary tools to ensure
                attainment of learning outcomes and it is compliant with
                multiple global program accreditations.
              </p>
              <p>
                QOBE is a product of <a href="http://alfoze.com" target="_blank" rel="noopener noreferrer">Alfoze</a>
              </p>
              <p>
                <Link to="/privacy">Privacy Policy</Link>
              </p>
            </div>

            <div className="col-md-3 mb-12">
              <h4>Accreditations Supported</h4>
              <ul className="list-unstyled link-list">
                <li>
                  <a
                    href="https://www.ieagreements.org/accords/washington/"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Washington Accord
                  </a>
                </li>
                <li>
                  <a href="https://www.abet.org/" target="_blank" rel="noopener noreferrer">
                    ABET{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.seoulaccord.org/" target="_blank" rel="noopener noreferrer">
                    Seoul Accord{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ieagreements.org/accords/sydney/"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Sydney Accord
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ieagreements.org/accords/dublin/ "
                    target="_blank" rel="noopener noreferrer"
                  >
                    Dublin Accord
                  </a>
                </li>
                <li>
                  <a href="https://www.aacsb.edu/" target="_blank" rel="noopener noreferrer">
                    AACSB{" "}
                  </a>
                </li>
                <li>
                  <a href="https://acbsp.org" target="_blank" rel="noopener noreferrer">
                    ACBSP{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 mb-12">
              <h4>Contact Us</h4>
              <ul className="list-unstyled link-list">
                <li>Sales & Partnerships</li>
                <li>
                  Email:
                  <a href="mailto:sales@alfoze.com" className="">
                    sales@alfoze.com
                  </a>
                </li>
                <li>WhatsApp +1 (804) 500-1282</li>
                <li>OR</li>
                <li>
                  <a href="https://qualityobe.com/site/contact" target="_blank" rel="noopener noreferrer">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2  mobile-hide">
              <img src="/imgs/aws.png" alt="AWS" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <a href="/">
                <img width="200" src="/imgs/logo.png" alt="" />
              </a>
            </div>
            <div className="col-md-5">
              <p >
                2015-2023 © All Rights Reserved. Alfoze Technologies (Pvt) Ltd.
              </p>
              <p
                className=" mobile-hide"
              >
                QOBE Version : 3.0.001
              </p>
            </div>

            <div className="col-md-4">
              <a
                href="https://www.facebook.com/AlfozeTechnologies"
                data-original-title="Facebook"
                target="_blank" rel="noopener noreferrer"
                className="nav-icon social-media i-Facebook-2"
              > </a>
              <a
                href="https://twitter.com/AlfozeTech"
                data-original-title="Twitter"
                target="_blank" rel="noopener noreferrer"
                className="nav-icon social-media i-Twitter-2"
              > </a>
              <a
                href="https://www.linkedin.com/company/alfoze-technologies?trk=cp_followed_name_alfoze-technologies"
                target="_blank" rel="noopener noreferrer"
                data-original-title="Linkedin"
                className="nav-icon social-media i-Linkedin-2"
              > </a>
            </div>
          </div>
        </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Footer;
