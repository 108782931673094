import React from "react";


function ClassRoomHead(props) {
  const baseUrl = process.env.REACT_APP_BACKEND_URL2;

  return (
    <>
      {props.classId === undefined ? (
        <></>
      ) : (
        <div>
          <div className="d-flex align-items-center">
            <img
              className="avatar-md rounded-circle mr-2"
              src={props.classData.teacherdp ===undefined?"": baseUrl+ props.classData.teacherdp}
              alt={props.classData.teacher}
            />
            <p className="m-0 text-title text-16 flex-grow-1">
              <strong>{props.classData.course}</strong>
              <br />
              <span>
                {props.classData.name}{" "}
                {props.classData.section === "" ? "" : "(" + props.classData.section + ")"}
              </span>
              <b> / </b>
              <span>{props.classData.teacher}</span>
              <b> / </b>
              <span>{props.classData.semester}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ClassRoomHead;
