import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import Modal from "react-modal";
import DataLoading from "../../components/DataLoading";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-40%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const attApi = axiosInstance;
function ClassForum(props) {
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const forumId = searchParams.get("id");
  const [classData, setClassData] = useState({
    comments: [],
    detail: "",
    allow_comment: false,
  });

  useEffect(() => {
    setTitle("Discussion Forum");
    setOperation("Discussion Forum");
    setPLink([{ name: "My Courses", link: "/classrooms" }]);
  }, [setTitle, setOperation, setPLink, forumId]);

  useEffect(() => {
    if (forumId !== undefined) {
      setLoading(true);
      attApi
        .get("/classforum?id=" + forumId)
        .then((res) => {
          if (res.status === 200) {
            setClassData(res.data);
            setPLink([
              { name: "My Courses", link: "/classrooms" },
              {
                name: res.data.courseSectionName,
                link: "/classroom?id=" + res.data.classId,
              },
              {
                name: "Discussion Forums",
                link: "/classroom/forums?id=" + res.data.classId,
              },
            ]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [forumId, setTitle, setPLink]);

  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }
  function handleChange2(event) {
    setUValues({ ...uValues, [event.target.id]: event.target.value });
    setErrorText2("");
  }

  const [errorText, setErrorText] = useState("");
  const [errorText2, setErrorText2] = useState("");
  const [values, setValues] = useState({ comment: "" });
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [uValues, setUValues] = useState({ comment: "" });
  const [uId, setUId] = useState(0);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function deleteComment(id) {
    if (window.confirm("Delete this comment, are you sure?")) {
      attApi
        .post("/deletecommentclassforum?id=" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              notify(res.data.message, "success");
              attApi.get("/classforum?id=" + forumId).then((res) => {
                if (res.status === 200) {
                  setClassData(res.data);
                }
              });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorText("Invalid arugument sent");
          } else if (err.response.status === 403) {
            setErrorText(err.response.data.message);
          } else {
            setErrorText(err.message);
          }
        });
    }
    // console.log(confirm("Delete this comment, are you sure?"))
  }
  function postcomment(e) {
    e.preventDefault();
    if (values.comment === "") {
      setErrorText("Comments can not be blank!");
      return;
    }
    var formData = new FormData();
    Object.keys(values).map(function (key) {
      return formData.append(key, values[key]);
    });

    attApi
      .post("/commentclassforum?id=" + forumId, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            setValues({ ...values, comment: "" });
            setErrorText("");
            attApi.get("/classforum?id=" + forumId).then((res) => {
              if (res.status === 200) {
                setClassData(res.data);
              }
            });
          } else {
            let messages = res.data.message;
            if (messages.reply) {
              notify(messages.reply[0], "error");
            }
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      });
  }
  function updatecomment(e) {
    e.preventDefault();
    if (uValues.comment === "") {
      setErrorText2("Comments can not be blank!");
      return;
    }
    var formData = new FormData();
    Object.keys(uValues).map(function (key) {
      return formData.append(key, uValues[key]);
    });

    attApi
      .post("/updatecommentclassforum?id=" + uId, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            setUValues({ ...uValues, comment: "" });
            setErrorText2("");
            attApi.get("/classforum?id=" + forumId).then((res) => {
              if (res.status === 200) {
                setClassData(res.data);
              }
            });
            closeModal();
          } else {
            let messages = res.data.message;
            if (messages.reply) {
              notify(messages.reply[0], "error");
            }
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText2("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText2(err.response.data.message);
        } else {
          setErrorText2(err.message);
        }
      });
  }
  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row mt-12 mb-12">
                <div className="col-md-12">
                  <h3>{classData.title}</h3>
                  <ul className="list-unstyled list-inline blog-info">
                    <li>
                      <i className="glyphicon glyphicon-calendar"></i>{" "}
                      {classData.posted_when}
                    </li>
                    <li>
                      <i className="glyphicon glyphicon-pencil"></i>{" "}
                      {classData.posted_by}
                    </li>
                    <li>
                      <i className="glyphicon glyphicon-comment"></i>{" "}
                      {classData.comment_count} Comments
                    </li>
                  </ul>
                </div>
                {classData.img === "" ? (
                  <></>
                ) : (
                  <div className="col-md-12">
                    <img
                      className="img-responsive"
                      src={classData.img}
                      alt=""
                    />
                  </div>
                )}
                <div className="col-md-12">
                  {classData.userType === "1" ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: classData.detail }}
                    />
                  ) : (
                    <>
                      {classData.detail.split("\n").map((item, key) => {
                        return (
                          <Fragment key={key}>
                            {item}
                            <br />
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {classData.allow_comment === false ? (
            <></>
          ) : (
            <div className="card mt-3">
              <div className="card-body">
                <div className="card-title">Comments</div>
                <div className="classfourmreply-form">
                  <form>
                    <div className="form-group field-classfourmreply-reply required">
                      <label className="control-label" htmlFor="comment">
                        Leave Comment
                      </label>
                      <textarea
                        id="comment"
                        className="form-control"
                        name="comment"
                        rows="2"
                        value={values.comment || ""}
                        aria-required="true"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <p className="required">{errorText}</p>
                    <div className="form-group">
                      <button onClick={postcomment} className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                {classData.comments.length === 0 ? (
                  <></>
                ) : (
                  <>
                    <hr />
                    <div className="card-title mt-24">Previous Comments</div>
                    <div id="w0" className="list-view">
                      {classData.comments.map(function (d, index) {
                        let cm = classData.comments[index];
                        return (
                          <div className="item" key={index}>
                            <div className="ul-widget-app__row-comments">
                              <div className="ul-widget-app__profile-pic p-3">
                                <img
                                  className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                                  src={cm.dp}
                                  alt="alt"
                                />
                              </div>
                              <div className="ul-widget-app__comment">
                                <div className="ul-widget-app__profile-title">
                                  <h6 className="heading">{cm.comment_by}</h6>
                                  <p className="mb-2">
                                    {cm.comment.split("\n").map((item, key) => {
                                      return (
                                        <Fragment key={key}>
                                          {item}
                                          <br />
                                        </Fragment>
                                      );
                                    })}
                                  </p>
                                  {cm.img === "" ? (
                                    <></>
                                  ) : (
                                    <img alt="" src={cm.img} />
                                  )}
                                </div>
                                <div className="ul-widget-app__profile-status">
                                  <span className="ul-widget-app__icons">
                                    {cm.allow_update === false ? (
                                      <></>
                                    ) : (
                                      <>
                                        <span
                                          role="button"
                                          title="Update"
                                          onClick={() => {
                                            setUValues({
                                              ...uValues,
                                              comment: cm.comment,
                                            });
                                            setUId(cm.id);
                                            openModal();
                                          }}
                                        >
                                          <i className="glyphicon glyphicon-edit text-mute mr-2"></i>
                                        </span>
                                        <span
                                          role="button"
                                          title="Delete"
                                          onClick={() => {
                                            deleteComment(cm.id);
                                          }}
                                        >
                                          <i className="glyphicon glyphicon-trash  text-mute"></i>
                                        </span>
                                      </>
                                    )}
                                  </span>
                                  <span className="text-mute">
                                    {cm.comment_when}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <div className="modal-header">
                  <h4 id="helpModalLabel">Update Comment</h4>
                </div>
                <div className="modal-body">
                  <div id="helpConetent">
                    <form>
                      <div className="form-group field-classfourmreply2-reply required">
                        <label className="control-label" htmlFor="comment">
                          Leave Comment
                        </label>
                        <textarea
                          id="comment"
                          value={uValues.comment}
                          onChange={handleChange2}
                          className="form-control"
                          name="comment"
                          rows="2"
                          style={{ width: "300px" }}
                          aria-required="true"
                        ></textarea>
                        <p className="required">{errorText2}</p>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={updatecomment}
                          className="btn btn-success mr-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className=" btn btn-danger"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ClassForum;
