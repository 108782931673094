import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";

import ObeTopMenu from "../../components/ObeTopMenu";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;
const attApi2 = axiosInstance;
function Obetranscript(props) {
  const { user, setTitle, setOperation, setPLink ,notify} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  function getTrans(id) {
    const axiosConf = {
      responseType: "arraybuffer",
    };
    notify("Please wait while file get downloaded!", "info")
    attApi2.get("/getobetranscript?id=" + id, axiosConf).then((response) => {
      if (response.status === 200) {
        let fileName = "OBE Transcript.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    });
  }
  const [semData, setSemData] = useState([]);
  useEffect(() => {
    setLoading(true);
    setTitle("Outcome Based Education Transcript");
    setOperation("Outcome Based Education Transcript");
    setPLink("");
    attApi
      .get("/obetranscriptlist")
      .then((res) => {
        if (res.status === 200) {
          setSemData(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setTitle, setOperation, setPLink]);

  return (
    <>
      <ObeTopMenu current={4} />
      {loading ? (
        <DataLoading />
      ) : (
        <div className="card">
          <div className="card-body">
            <p className="mt-3">
              <strong>
                Please download pdf of Outcome Based Education Transcript
              </strong>
            </p>
            {Object.keys(semData).map(function (key) {
              let d = semData[key];
              return (
                <React.Fragment key={key}>
                  <span
                    download
                    onClick={() => getTrans(d.semster_id)}
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "#663399",
                    }}
                  >
                    <i className="glyphicon glyphicon-download-alt mr-2 mt-3"></i>
                    {d.name}
                  </span>
                  <br />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Obetranscript;
