import React, {  useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";

import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function ClassTimeTable(props) {
  const classId = props.classId;

  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  const columns = [
       {
      name: "Date",
      accessor: "date",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.date}</div>
      ),
      sortable: true,
    },
    {
      name: "From Time",
      accessor: "from_time",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.from_time}</div>
      ),
      sortable: true,
    },
    {
      name: "To Time",
      accessor: "to_time",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.to_time}</div>
      ),
      sortable: true,
    },
    {
      name: "Topic",
      accessor: "topic",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.topic}</div>
      ),
      sortable: true,
    },
    {
      name: "Room No.",
      sortable: true,
      accessor: "room",
      selector: (row) =><div className="text-wrap ">{ row.room}</div>,
    },
    {
        name: "Teacher",
        sortable: true,
        accessor: "teacher",
        selector: (row) =><div className="text-wrap ">{ row.teacher}</div>,
      },
  ];

  useEffect(() => {
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classtimetable?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);
        }
      });
    }
  }, [classId, props.perMarks]);

  return (
    <div>
      <h3>Time Table</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
        fixedHeader
        highlightOnHover
        striped
      />
    </div>
  );
}

export default ClassTimeTable;