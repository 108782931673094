import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Cqi(props) {
  const {  user } = useContext(UserContext);
  const classId = props.classId;

  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  const columns = [
    {
      name: "Status",
      sortable: true,
      accessor: "status",
      selector: (row) => row.status,
      width: "100px",
    },

    {
      name: "CAR No.",
      accessor: "doc_no",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.doc_no}</div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      accessor: "doc_date",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.doc_date}</div>
      ),
      sortable: true,
    },
    {
      name: "Originator",
      accessor: "originator",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.originator}</div>
      ),
      sortable: true,
    },
    {
      name: "Reference No.",
      accessor: "car_ref",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.car_ref}</div>
      ),
      sortable: true,
    },
    {
      name: "Problem",
      accessor: "problem",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.problem}</div>
      ),
      sortable: true,
    },
    {
      name: user.cloName,
      sortable: true,
      accessor: "clos",
      selector: (row) =><div className="text-wrap ">{ row.clos}</div>,
      width: "100px",
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classcqi?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);
        }
      });
    }
  }, [classId,  props.perMarks]);

  return (
    <div>
      <h3>CQI</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
        fixedHeader
        highlightOnHover
        striped
      />
    </div>
  );
}

export default Cqi;
