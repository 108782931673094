import React, {  useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Media(props) {
  const classId = props.classId;

  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  const columns = [
    {
      name: "",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">
          <h5>{row.name}</h5>
          <p className="mt-24 mb-12">
            <iframe
              title={row.video}
              width="560"
              height="315"
              src={row.video}
              frameBorder="0"
              allow=" autoplay; encrypted-media; "
              allowFullScreen
            ></iframe>
          </p>
          <p>{row.description}</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classmedia?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [classId]);

  return (
    <div>
      <h3>Video Material</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
       
      />
    </div>
  );
}

export default Media;
