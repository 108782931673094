import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Register(props) {
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [classRoomBased, setClassRoomBased] = useState(false);
  const [mstData, setMstData] = useState([]);
  const [dtlData, setDtlData] = useState([]);
  const [dtlVals, setDtlVals] = useState([]);

  function handleChange(event) {
    setDtlVals({ ...dtlVals, [event.target.id]: Number(event.target.value) });
  }

  function handleCheckChange(event) {
    let olfVal = dtlVals;
    if (classRoomBased === "1") {
      if (event.target.checked === false) {
        let clKey = event.target.id;
        clKey = clKey.replace("course_id", "class_room_id");
        olfVal = { ...olfVal, [clKey]: null };
      }
    }
    olfVal = { ...olfVal, [event.target.id]: event.target.checked ? "1" : "0" };
    setDtlVals(olfVal);
  }

  function saveRegistration(e) {
    e.preventDefault();
    var formData = new FormData();
    Object.keys(dtlVals).map(function (key) {
      let data = dtlVals[key];
      return formData.append(key, data);
    });

    attApi
      .post("/savecourseregistration", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            fetchData();
          } else {
            let messages = res.data.message;
            notify(messages, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify("Invalid arugument sent", "error");
        } else if (err.response.status === 403) {
          notify(err.response.data.message, "error");
        } else {
          notify(err.response.data.message, "error");
        }
      });
  }
  const fetchData = () => {
    setLoading(true);
    attApi
      .get("/getcourseregistration")
      .then((res) => {
        if (res.status === 200) {
          setClassRoomBased(res.data.classroom_base_reg);
          setMstData(res.data.regMst);
          setDtlData(res.data.regCourseDtl);
          setDtlVals(res.data.regDetailData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
    setTitle("Course Registration");
    setOperation("Course Registration");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);
  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <div>
          {mstData.map(function (d, index) {
            let semData = dtlData[d.id] === undefined ? {} : dtlData[d.id];

            return (
              <div key={index}>
                <h4>{d.name}</h4>
                <p>
                  Please make sure to register between {d.start_date} and{" "}
                  {d.end_date}
                </p>
                {Object.keys(semData).map(function (key2) {
                  let courseData = semData[key2];
                  return (
                    <div key={key2}>
                      <h4>{key2}</h4>
                      <div className="card mb-3">
                        <div className="card-body">
                          {courseData.map(function (course, index) {
                            let id =
                              "Courseregdtl[" +
                              d.id +
                              "][" +
                              course.course_id +
                              "][course_id]";
                            let chkd =
                              dtlVals[id] === undefined
                                ? false
                                : dtlVals[id] === "1"
                                ? true
                                : false;
                            return (
                              <React.Fragment key={index}>
                                <label
                                  className="checkbox checkbox-primary"
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    id={id}
                                    value="1"
                                    onChange={handleCheckChange}
                                    checked={chkd}
                                    disabled={course.disabled ? true : false}
                                  />
                                  <span
                                    style={{
                                      color: course.disabled
                                        ? "#a8a5a5"
                                        : "#70657b",
                                    }}
                                  >
                                    {course.label}
                                  </span>
                                  <span className="checkmark"></span>
                                </label>
                                {classRoomBased === "1" ? (
                                  <div className="form-group ">
                                    <div role="radiogroup">
                                      {course.classrooms.map(function (
                                        classroom,
                                        index2
                                      ) {
                                        let id2 =
                                          "Courseregdtl[" +
                                          d.id +
                                          "][" +
                                          course.course_id +
                                          "][class_room_id]";

                                        return (
                                          <React.Fragment key={index2}>
                                            <label className="ml-5">
                                              <input
                                                type="radio"
                                                id={id2}
                                                value={classroom.id}
                                                onChange={handleChange}
                                                checked={
                                                  dtlVals[id2] === classroom.id
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <span className="ml-2">
                                                {classroom.name}
                                                {classroom.section === ""
                                                  ? ""
                                                  : " - " + classroom.section}
                                                {" - " + classroom.teacher}
                                                {classroom.timing === ""
                                                  ? ""
                                                  : "[" +
                                                    classroom.timing +
                                                    "]"}
                                              </span>
                                            </label>
                                            <br></br>
                                          </React.Fragment>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
          {mstData.length > 0 ? (
            <div className="form-group mt-12">
              <button className="btn btn-success" onClick={saveRegistration}>
                Save
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

export default Register;
