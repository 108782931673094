import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";

function ObeTopMenu(props) {
  const { user } = useContext(UserContext);

  return (
    <ul className="nav nav-pills">
      {props.current === 1 ? (
        <></>
      ) : (
        <li className="nav-item">
          <Link className="nav-link" to="/cloattainment">
            {user.cloPName} Attainment
          </Link>
        </li>
      )}

      {props.current === 2 ? (
        <></>
      ) : (
        <li className="nav-item">
          <Link className="nav-link" to="/ploattainment">
            {user.ploPName} Attainment
          </Link>
        </li>
      )}
      {props.current === 3 ? (
        <></>
      ) : (
        <li className="nav-item">
          <Link className="nav-link" to="/plograph">
            {user.ploPName} Attainment Graph
          </Link>
        </li>
      )}
      {props.current === 4 ? (
        <></>
      ) : (
        <li className="nav-item">
          <Link className="nav-link" to="/transcriptobe">
            Outcome Based Education Transcript
          </Link>
        </li>
      )}
    </ul>
  );
}

export default ObeTopMenu;
