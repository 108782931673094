import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";

function Breadcrumb(props) {
  const { user } = useContext(UserContext);
  return (
    <>
      {user ? (
        props.title ? (
          <>
            <div className="breadcrumb ul-widget4__users">
              <h1>{props.title}</h1>
              <ul className="float-right">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {props.plink.length === 0 ? (
                  <></>
                ) : (
                  props.plink.map(function (d, index) {
                    return (
                  <li key={index}>
                    <Link to={d.link}>{d.name}</Link>
                  </li>
                    )
                  })
                )}
                <li>{props.operation}</li>
              </ul>
            </div>
            <div className="separator-breadcrumb border-top mb-2" />
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default Breadcrumb;
