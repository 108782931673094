import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const profileApi = axiosInstance;
function UpdateProfile(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setTitle, setOperation, setPLink, notify,refreshUser } = useContext(UserContext);
  const [profileData, setProfileData] = useState([]);
  const [values, setValues] = useState({});
  const [errorText, setErrorText] = useState("");
  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }

  function update(e) {
    e.preventDefault();
    var formData = new FormData();
    Object.keys(values).map(function (key) {
      return formData.append(key, values[key]);
    });

    profileApi
      .post("/updateprofile", formData)
      .then((res) => {
        if (res.status === 200) {
          navigate("/profile");
          if (res.data.success === true) {
            notify(res.data.message, "success");
            refreshUser()

          } else {
            let messages = res.data.message;
            messages.map(function (key) {
              return notify(key, "error");
            });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    setTitle("Update Profile");
    setOperation("Update");

    setPLink([{ name: "Profile", link: "/profile" }]);

    profileApi
      .get("/profile")
      .then((res) => {
        if (res.status === 200) {
          setProfileData(res.data);
          setValues(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink]);
  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <table className="mb-12">
            <tbody>
              <tr>
                <td>
                  <img
                    className="avatar-sm rounded-circle mr-24"
                    src={profileData.dp}
                    alt="DP"
                  />
                </td>
                <td>
                  <h4 className="d-inline">
                    {profileData.reg_no} - {profileData.name}
                  </h4>
                  <br />
                  <p className="d-inline">{profileData.program}</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="required">{errorText}</p>
          <div className="card">
            <div className="card-body">
              <div className="form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group field-intro">
                        <label className="control-label" htmlFor="intro">
                          Introduction
                        </label>
                        <input
                          type="text"
                          id="intro"
                          className="form-control"
                          name="intro"
                          value={values.intro || ""}
                          maxLength="500"
                          placeholder=" I am graduate in Bussiness Administration and have 1 year of experience"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group field-email">
                        <label className="control-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          name="email"
                          value={values.email || ""}
                          maxLength="200"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group field-passport">
                        <label className="control-label" htmlFor="passport">
                          Passport
                        </label>
                        <input
                          type="text"
                          id="passport"
                          className="form-control"
                          name="passport"
                          value={values.passport || ""}
                          maxLength="45"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group field-marital_status">
                        <label
                          className="control-label"
                          htmlFor="marital_status"
                        >
                          Marital Status
                        </label>
                        <select
                          id="marital_status"
                          className="form-control"
                          name="marital_status"
                          value={values.marital_status || ""}
                          onChange={handleChange}
                        >
                          <option value="">-Select-</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </select>

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group field-religion">
                        <label className="control-label" htmlFor="religion">
                          Religion
                        </label>
                        <select
                          id="religion"
                          className="form-control"
                          name="religion"
                          onChange={handleChange}
                          value={values.religion || ""}
                        >
                          <option value="">-Select-</option>
                          <option value="Muslim">Muslim</option>
                          <option value="Christian">Christian</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Ahmadi">Ahmadi</option>
                          <option value="Other">Other</option>
                        </select>

                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group field-cell">
                        <label className="control-label" htmlFor="cell">
                          Mobile
                        </label>
                        <input
                          type="text"
                          id="cell"
                          className="form-control"
                          name="cell"
                          value={values.cell || ""}
                          maxLength="15"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group field-home_phone">
                        <label className="control-label" htmlFor="home_phone">
                          Home Phone
                        </label>
                        <input
                          type="text"
                          id="home_phone"
                          className="form-control"
                          name="home_phone"
                          value={values.home_phone || ""}
                          maxLength="20"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group field-next_kin">
                        <label className="control-label" htmlFor="next_kin">
                          Next of Kin Name
                        </label>
                        <input
                          type="text"
                          id="next_kin"
                          className="form-control"
                          name="next_kin"
                          value={values.next_kin || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group field-next_kin_mobile">
                        <label
                          className="control-label"
                          htmlFor="next_kin_mobile"
                        >
                          Next of Kin Mobile
                        </label>
                        <input
                          type="text"
                          id="next_kin_mobile"
                          className="form-control"
                          name="next_kin_mobile"
                          value={values.next_kin_mobile || ""}
                          maxLength="15"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group field-address">
                        <label className="control-label" htmlFor="address">
                          Permanent Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          className="form-control"
                          name="address"
                          value={values.address || ""}
                          maxLength="500"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group field-city">
                        <label className="control-label" htmlFor="city">
                          Current City
                        </label>
                        <input
                          type="text"
                          id="city"
                          className="form-control"
                          name="city"
                          value={values.city || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group field-district">
                        <label className="control-label" htmlFor="district">
                          District
                        </label>
                        <input
                          type="text"
                          id="district"
                          className="form-control"
                          name="district"
                          value={values.district || ""}
                          maxLength="30"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group field-province">
                        <label className="control-label" htmlFor="province">
                          Province
                        </label>
                        <input
                          type="text"
                          id="province"
                          className="form-control"
                          name="province"
                          value={values.province || ""}
                          maxLength="20"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group field-country">
                        <label className="control-label" htmlFor="country">
                          Country
                        </label>
                        <input
                          type="text"
                          id="country"
                          className="form-control"
                          name="country"
                          value={values.country || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group field-postal_address">
                        <label
                          className="control-label"
                          htmlFor="postal_address"
                        >
                          Postal Address
                        </label>
                        <input
                          type="text"
                          id="postal_address"
                          className="form-control"
                          name="postal_address"
                          value={values.postal_address || ""}
                          maxLength="500"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group field-relocate">
                        <label className="control-label" htmlFor="relocate">
                          Willing to Relocate?
                        </label>
                        <select
                          id="relocate"
                          className="form-control"
                          name="relocate"
                          value={values.relocate || ""}
                          onChange={handleChange}
                        >
                          <option value="0">N/A</option>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </select>

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group field-experirence">
                        <label className="control-label" htmlFor="experirence">
                          Work Experience (Years)
                        </label>
                        <input
                          type="number"
                          id="experirence"
                          className="form-control"
                          name="experirence"
                          value={values.experirence || ""}
                          step="0.1"
                          min="0"
                          max="60"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group field-emp_status">
                        <label className="control-label" htmlFor="emp_status">
                          Employement Status
                        </label>
                        <select
                          id="emp_status"
                          className="form-control"
                          name="emp_status"
                          value={values.emp_status || "0"}
                          onChange={handleChange}
                        >
                          <option value="0">N/A</option>
                          <option value="E"> Employeed </option>
                          <option value="U">Un-Emplpyeed</option>
                        </select>

                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group field-website">
                        <label className="control-label" htmlFor="website">
                          Website
                        </label>
                        <input
                          type="text"
                          id="website"
                          className="form-control"
                          name="website"
                          value={values.website || ""}
                          maxLength="500"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group field-fb">
                        <label className="control-label" htmlFor="fb">
                          Facebook ID
                        </label>
                        <input
                          type="text"
                          id="fb"
                          className="form-control"
                          name="fb"
                          value={values.fb || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group field-linkedin">
                        <label className="control-label" htmlFor="linkedin">
                          LinkedIn ID
                        </label>
                        <input
                          type="text"
                          id="linkedin"
                          className="form-control"
                          name="linkedin"
                          value={values.linkedin || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group field-tweeter">
                        <label className="control-label" htmlFor="tweeter">
                          Twitter Handle
                        </label>
                        <input
                          type="text"
                          id="tweeter"
                          className="form-control"
                          name="tweeter"
                          value={values.tweeter || ""}
                          maxLength="100"
                          onChange={handleChange}
                        />

                        <div className="help-block"></div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-24">
                    <button onClick={update} className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UpdateProfile;
