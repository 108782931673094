import React, { useContext, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
const attApi = axiosInstance;

function Video(props) {
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState({});
  const [comments, setComments] = useState([]);
  const [searchParams] = useSearchParams();
  const videoId = searchParams.get("id");
  const [errorText, setErrorText] = useState("");
  const [values, setValues] = useState({ comment: "" });
  
  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }
  
  function deleteComment(id) {
    if (window.confirm("Delete this comment, are you sure?")) {
      attApi
        .post("/deletecommentvideo?id=" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              notify(res.data.message, "success");
              attApi.get("/getvideocomments?id=" + videoId).then((res) => {
                if (res.status === 200) {
                  setComments(res.data);
                }
              });
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorText("Invalid arugument sent");
          } else if (err.response.status === 403) {
            setErrorText(err.response.data.message);
          } else {
            setErrorText(err.message);
          }
        });
    }
    // console.log(confirm("Delete this comment, are you sure?"))
  }
  function postcomment(e) {
    e.preventDefault();
    if (values.comment === "") {
      setErrorText("Comments can not be blank!");
      return;
    }
    var formData = new FormData();
    Object.keys(values).map(function (key) {
      return formData.append(key, values[key]);
    });

    attApi
      .post("/commentvideo?id=" + videoId, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            setValues({ ...values, comment: "" });
            setErrorText("");
            attApi.get("/getvideocomments?id=" + videoId).then((res) => {
              if (res.status === 200) {
                setComments(res.data);
              }
            });
          } else {
            let messages = res.data.message;
            if (messages.reply) {
              notify(messages.reply[0], "error");
            }
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      });
  }
 

  useEffect(() => {
    setLoading(true);
    setTitle("Tutorial");
    setOperation("Tutorial");
    setPLink([{ name: "Tutorials", link: "/videos" }]);
    if (videoId !== "") {
      attApi
        .get("/getvideo?id=" + videoId)
        .then((res) => {
          if (res.status === 200) {
            setTitle(res.data.title);
            setVideos(res.data);
            
          }
        })
        .finally(() => {
          setLoading(false);
        });

        attApi
        .get("/getvideocomments?id=" + videoId)
        .then((res) => {
          if (res.status === 200) {
            setComments(res.data);
            console.log(res.data);
            
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [setTitle, setOperation, setPLink,videoId]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-9">
              <div className="embed-container">
                <iframe
                  title={videos.title}
                  src={"https://player.vimeo.com/video/" + videos.video_id}
                  frameBorder="0"
                  webkitallowfullscreen ='true'
                  mozallowfullscreen = "true"
                  allowFullScreen
                ></iframe>
              </div>

              <p>{videos.detail}</p>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title">Comments</div>
              <div className="classfourmreply-form">
                <form>
                  <div className="form-group field-classfourmreply-reply required">
                    <label className="control-label" htmlFor="comment">
                      Leave Comment
                    </label>
                    <textarea
                      id="comment"
                      className="form-control"
                      name="comment"
                      rows="2"
                      value={values.comment || ""}
                      aria-required="true"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <p className="required">{errorText}</p>
                  <div className="form-group">
                    <button onClick={postcomment} className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              {comments.length === 0 ? (
                <></>
              ) : (
                <>
                  <hr />
                  <div className="card-title mt-24">Previous Comments</div>
                  <div id="w0" className="list-view">
                    {comments.map(function (d, index) {
                      let cm = d;
                      return (
                        <div className="item" key={index}>
                          <div className="ul-widget-app__row-comments">
                            <div className="ul-widget-app__profile-pic p-3">
                              <img
                                className="profile-picture avatar-md mb-2 rounded-circle img-fluid"
                                src={cm.dp}
                                alt="alt"
                              />
                            </div>
                            <div className="ul-widget-app__comment">
                              <div className="ul-widget-app__profile-title">
                                <h6 className="heading">{cm.comment_by}</h6>
                                <p className="mb-2">
                                  {cm.comment.split("\n").map((item, key) => {
                                    return (
                                      <Fragment key={key}>
                                        {item}
                                        <br />
                                      </Fragment>
                                    );
                                  })}
                                </p>
                                
                              </div>
                              <div className="ul-widget-app__profile-status">
                                <span className="ul-widget-app__icons">
                                  {cm.allow_update === false ? (
                                    <></>
                                  ) : (
                                    <>
                                      <span
                                        role="button"
                                        title="Delete"
                                        onClick={() => {
                                          deleteComment(cm.id);
                                        }}
                                      >
                                        <i className="glyphicon glyphicon-trash  text-mute"></i>
                                      </span>
                                    </>
                                  )}
                                </span>
                                <span className="text-mute">
                                  {cm.comment_when}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Video;
