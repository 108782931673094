import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const profileApi = axiosInstance;
function UpdateProfileImage(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setTitle, setOperation, setPLink, notify,refreshUser } = useContext(UserContext);
  const [profileData, setProfileData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorText, setErrorText] = useState("");

  function setPreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      profileData.img = "";
      setSelectedImage(e.target.files[0]);
    }
  }

  function update(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("file", selectedImage);
    profileApi
      .post("/profileupload", formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            navigate("/profile");
            refreshUser()
            notify(res.data.message, "success");
          } else {
            notify(res.data.message, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorText(err.response.data.message);
        } else {
          setErrorText(err.message);
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    setTitle("Update Profile Image");
    setOperation("Update");

    setPLink([{ name: "Profile", link: "/profile" }]);

    profileApi
      .get("/profile")
      .then((res) => {
        if (res.status === 200) {
          setProfileData(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <table className="mb-12">
            <tbody>
              <tr>
                <td>
                  <img
                    className="avatar-sm rounded-circle mr-24"
                    src={profileData.dp}
                    alt="DP"
                  />
                </td>
                <td>
                  <h4 className="d-inline">
                    {profileData.reg_no} - {profileData.name}
                  </h4>
                  <br />
                  <p className="d-inline">{profileData.program}</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="required">{errorText}</p>
          <div className="card">
            <div className="card-body">
              <div className="form">
                <input
                  type="file"
                  name={"file"}
                  id={"file"}
                  onChange={(e) => {
                    setPreview(e);
                  }}
                  accept="image/png, image/jpeg"
                />
                {
                  profileData.img === ''?<></>:
                  <img src={profileData.img} alt="DP" className="img-responsive mt-2 mb-2"/>
                }
                {
                  selectedImage === null?<></>:
                  <img src={URL.createObjectURL(selectedImage)} alt="DP" className="img-responsive mt-2 mb-2" style={{maxWidth:"400px", maxHeight:"400px"}}/>
                }
                <div className="form-group mt-24">
                  <button onClick={update} className="btn btn-success">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UpdateProfileImage;
