import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Clos(props) {
  const {  user } = useContext(UserContext);
  const classId = props.classId;
  const ploBase = props.ploBase;
  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  createTheme('solarized', {
    text: {
      primary: '#222121',
      secondary: '#2aa198',
    },
    background: {
      default: '#e7eaee',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'light');
  const columns2 = [
    { name: user.ploName,
        accessor: "plo",
        selector: (row) => (
          <div className="text-wrap mt-2 mb-2">{row.plo}</div>
        ), 
    },
    { name: "Learning Type", selector: (row) => row.learning_type },
    { name: "Level", selector: (row) => row.level },
    { name: "Emphasis Level", selector: (row) => row.emphasis },
  ];
  const columns = [
    {
      name: "#",
      selector: (row, index, id) => index + 1,
      width: "40px",
    },
    {
      name: "Code",
      sortable: true,
      accessor: "code",
      selector: (row) => row.code,
      width: "100px",
    },
    {
      name: "Description",
      accessor: "description",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.description}</div>
      ),
      sortable: true,
    },
  ];
  
  useEffect(() => {
    
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classclos?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [classId]);

  const ExpandedComponent = ({ data }) => (
    ploBase === '1'?<></>:
    <div className="pl-5 bg-light">
      <DataTable title = {data.code+ " Mapping"} columns={columns2} data={data.plos}  theme="solarized"/>
    </div >
  );
  return (
    <div>
      <h3>{ploBase === '1'?user.ploBName:user.cloBName}</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
        fixedHeader
        highlightOnHover
        striped
        expandableRows
        expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
}

export default Clos;
