import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import ForumCard from "../../components/ForumCard";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  Modal.setAppElement("#root");
function Forums(props) {
  const { notify } = useContext(UserContext);
  const classId = props.classId;

  const [classData, setClassData] = useState({});
  
  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }
  const [errorText, setErrorText] = useState("");
  const [values, setValues] = useState({ title: "" , detail:""});
  const [modalIsOpen, setIsOpen] = React.useState(false);


  function refreshData(){
    attApi.get("/classforums?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
        }
      });
  }
  function createForum(e){
    e.preventDefault();
    if (values.title === "") {
        setErrorText("Title can not be blank!");
        return;
      }
      e.preventDefault();
    if (values.detail === "") {
        setErrorText("Detail can not be blank!");
        return;
      }
      var formData = new FormData();
      Object.keys(values).map(function (key) {
        return formData.append(key, values[key]);
      });
  
      attApi
        .post("/createclassforum?id=" + classId, formData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              notify(res.data.message, "success");
              setValues({ ...values, title: "" , detail:"" });
              setErrorText("");
              refreshData();
              closeModal();
            } else {
              let messages = res.data.message;
              if (messages.reply) {
                notify(messages.reply[0], "error");
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorText("Invalid arugument sent");
          } else if (err.response.status === 403) {
            setErrorText(err.response.data.message);
          } else {
            setErrorText(err.message);
          }
        });
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (classId !== undefined) {
      attApi.get("/classforums?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
        }
      });
    }
  }, [classId]);

  return (
    <div>
      <h3>Discussion Forums</h3>
      <p>
        Total <b>{classData.length}</b> items.

        <button className="btn btn-outline-success float-right mr-4"
         onClick={()=>openModal()}>
            Start
        </button>
      </p>
      <div>
        {Object.keys(classData).map(function (key) {
          return <ForumCard key={key} data={classData[key]} refreshData={refreshData}></ForumCard>;
        })}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="modal-header">
          <h4 id="helpModalLabel">Start Discussion Forum Thread</h4>
        </div>
        <div className="modal-body">
          <div id="helpConetent">
            <form>
              <div className="form-group field-classforum-name required ">
                <label className="control-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  name="title"
                  maxLength="200"
                  aria-required="true"
                  aria-invalid="true"
                  value={values.title}
                  onChange={handleChange}
                  style={{ width: "90vw" }}
                />
                <p className="required">{errorText}</p>
              </div>
              <div className="form-group field-classforum-question has-success">
                <label className="control-label" htmlFor="detail">
                  Detail
                </label>
                <textarea
                  id="detail"
                  className="form-control"
                  name="detail"
                  rows="10"
                  aria-invalid="false"
                  value={values.detail}
                  onChange={handleChange}
                ></textarea>

                
              </div>
              
              <div className="form-group">
                <button
                  onClick={createForum}
                  className="btn btn-success mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  className=" btn btn-danger"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Forums;
