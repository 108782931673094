import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { UserContext } from "./UserContext";
import axiosInstance from "../helper/axios";

const attApi = axiosInstance;
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  Modal.setAppElement("#root");

function ForumCard(props) {

  const {notify } = useContext(UserContext);
  function handleChange(event) {
    setValues({ ...values, [event.target.id]: event.target.value });
    setErrorText("");
  }
  const [errorText, setErrorText] = useState("");
  const [values, setValues] = useState({ title: "", detail:""});
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function updateForum(e){
    e.preventDefault();
    console.log(values);
    if (values.title === "") {
        setErrorText("Title can not be blank!");
        return;
      }
      e.preventDefault();
    if (values.detail === "") {
        setErrorText("Detail can not be blank!");
        return;
      }
      var formData = new FormData();
      Object.keys(values).map(function (key) {
        return formData.append(key, values[key]);
      });
  
      attApi
        .post("/updateclassforum?id=" + props.data.id, formData)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              notify(res.data.message, "success");
              setValues({ ...values, title: props.data.title , detail:props.data.detail });
              setErrorText("");
              props.refreshData();
              closeModal();
            } else {
              let messages = res.data.message;
              if (messages.reply) {
                notify(messages.reply[0], "error");
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorText("Invalid arugument sent");
          } else if (err.response.status === 403) {
            setErrorText(err.response.data.message);
          } else {
            setErrorText(err.message);
          }
        });
  }
  function openModal() {
    attApi.get("/classforum?id=" + props.data.id).then((res) => {
      if (res.status === 200) {
        setValues(res.data);        
      }
    });
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteForum() {
    if (window.confirm("Delete this Forum, are you sure?")) {
      attApi
        .post("/deleteclassforum?id=" + props.data.id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              notify(res.data.message, "success");    
              props.refreshData();          
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setErrorText("Invalid arugument sent");
          } else if (err.response.status === 403) {
            setErrorText(err.response.data.message);
          } else {
            setErrorText(err.message);
          }
        });
    }
    // console.log(confirm("Delete this comment, are you sure?"))
  }
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row mt-12 mb-12">
          <div className="col-md-12">
            <h3>{props.data.title}</h3>
            <ul className="list-unstyled list-inline blog-info">
              <li>
                <i className="glyphicon glyphicon-calendar"></i>{" "}
                {props.data.posted_when}
              </li>
              <li>
                <i className="glyphicon glyphicon-pencil"></i>{" "}
                {props.data.posted_by}
              </li>
              <li>
                <i className="glyphicon glyphicon-comment"></i>{" "}
                {props.data.comments} Comments
              </li>
            </ul>
          </div>
          {props.data.img === "" ? (
            <></>
          ) : (
            <div className="col-md-12">
              <img className="img-responsive" src={props.data.img} alt="" />
            </div>
          )}
          <div className="col-md-12">
            <p className="mt-12">{props.data.detail}</p>
            <p style={{ marginTop: "10px" }}>
              <Link
                className="btn btn-outline-primary"
                to={"/classforum?id=" + props.data.id}
                data-pjax="0"
              >
                <i className="glyphicon glyphicon glyphicon-new-window mr-1"></i>
                Read More
              </Link>
              {props.data.allow_update === true ? (
                <>
                  <button className="btn btn-outline-primary ml-2" onClick={()=> {openModal()}}>
                    <i className="glyphicon glyphicon glyphicon-edit mr-1"></i>
                    Update
                  </button>
                  <button className="btn btn-outline-danger ml-2" onClick={deleteForum}>
                    <i className="glyphicon glyphicon glyphicon-trash mr-1"></i>
                    Delete
                  </button>
                </>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="modal-header">
          <h4 id="helpModalLabel">Update Discussion Forum</h4>
        </div>
        <div className="modal-body">
          <div id="helpConetent">
            <form>
              <div className="form-group field-classforum-name required ">
                <label className="control-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  name="title"
                  maxLength="200"
                  aria-required="true"
                  aria-invalid="true"
                  value={values.title}
                  onChange={handleChange}
                  style={{ width: "90vw" }}
                />
                <p className="required">{errorText}</p>
              </div>
              <div className="form-group field-classforum-question has-success">
                <label className="control-label" htmlFor="detail">
                  Detail
                </label>
                <textarea
                  id="detail"
                  className="form-control"
                  name="detail"
                  rows="10"
                  aria-invalid="false"
                  value={values.detail}
                  onChange={handleChange}
                ></textarea>

                
              </div>
              
              <div className="form-group">
                <button
                  onClick={updateForum}
                  className="btn btn-success mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  className=" btn btn-danger"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ForumCard;
