import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Videos(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
}
  useEffect(() => {
    setLoading(true);
    setTitle("Tutorials");
    setOperation("Tutorials");
    setPLink("");

    attApi
      .get("/getvideos")
      .then((res) => {
        if (res.status === 200) {
          setVideos(res.data);
          console.log(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <div className="row">
          {videos.map(function (d, index) {
            return (
              <div className="col-lg-4 col-xl-4 mt-3">
                <div className="card">
                  <Link to={"/video?id=" + d.id}>
                    <img
                      className="d-block w-100 rounded"
                      src={d.thumbnail}
                      alt={d.title}
                    />
                  </Link>
                  <div className="card-body">
                    <Link to={"/video?id=" + d.id}>
                      <h6
                        className="card-title mb-2"
                        style={{ minHeight: "50px" }}
                      >
                        {d.title}
                      </h6>
                    </Link>
                    <p className="card-text text-mute">{secondsToHms(d.duration)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Videos;
