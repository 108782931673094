import React, { useState } from "react";
import { Link } from "react-router-dom";

function ClassRoomTopMenu(props) {
  const [showResource, setShowResource] = useState(false);
  const [showOBE, setShowOBE] = useState(false);
  const ploBase = props.ploBase;

  return (
    <ul className="nav nav-tabs mt-3" id="" role="tablist">
      <li className="nav-item">
        <Link
          className={props.current === 1 ? "nav-link active " : "nav-link"}
          to={"/classroom?id=" + props.classId}
          role="tab"
          aria-selected="false"
          title=""
          onClick={() => {
            props.setCurrentTab(1);
            props.setSubCurrentTab(1);
            setShowOBE(false);
            setShowResource(false);
          }}
        >
          View
        </Link>
      </li>

      <li className="nav-item dropdown">
        <span
          className={
            props.current === 2
              ? "nav-link dropdown-toggle active "
              : "nav-link dropdown-toggle"
          }
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title=""
          onClick={() => {
            setShowResource(!showResource);
            setShowOBE(false);
          }}
        >
          Resources
        </span>
        <div
          className="dropdown-menu"
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            left: "0px",
            transform: "translate3d(0px, 55px, 0px)",
            display: showResource ? "block" : "none",
          }}
        >
          <Link
            className={
              props.subCurrentTab === 2.1
                ? "dropdown-item active"
                : "dropdown-item "
            }
            to={"/classroom/media?id=" + props.classId}
            aria-expanded="true"
            onClick={() => {
              props.setCurrentTab(2);
              props.setSubCurrentTab(2.1);
              setShowOBE(false);
              setShowResource(false);
            }}
          >
            Video Material
          </Link>
          <Link
            className={
              props.subCurrentTab === 2.2
                ? "dropdown-item active"
                : "dropdown-item "
            }
            to={"/classroom/classplan?id=" + props.classId}
            aria-expanded="true"
            onClick={() => {
              props.setCurrentTab(2);
              props.setSubCurrentTab(2.2);
              setShowOBE(false);
              setShowResource(false);
            }}
          >
            {ploBase==='1'? 'Milestone / Deliverable Plan':'Teaching Plan'}
          </Link>

          <Link
            className={
              props.subCurrentTab === 2.3
                ? "dropdown-item active"
                : "dropdown-item "
            }
            to={"/classroom/attachments?id=" + props.classId}
            aria-expanded="true"
            onClick={() => {
              props.setCurrentTab(2);
              props.setSubCurrentTab(2.3);
              setShowOBE(false);
              setShowResource(false);
            }}
          >
            Other Resources
          </Link>
        </div>
      </li>

      {props.showTimeTable === true ? (
        <li className="nav-item">
          <Link
            className={props.current === 3 ? "nav-link active " : "nav-link"}
            to={"/classroom/timetable?id=" + props.classId}
            role="tab"
            aria-selected="false"
            title=""
            onClick={() => {
              props.setCurrentTab(3);
              props.setSubCurrentTab(3);
              setShowOBE(false);
              setShowResource(false);
            }}
          >
            Time Table
          </Link>
        </li>
      ) : (
        <></>
      )}
      {props.showObe === true ? (
        <li className="nav-item dropdown">
          <span
            className={
              props.current === 4
                ? "nav-link dropdown-toggle active "
                : "nav-link dropdown-toggle"
            }
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title=""
            onClick={() => {
              setShowOBE(!showOBE);
              setShowResource(false);
            }}
          >
            {props.obeLabel}
          </span>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 55px, 0px)",
              display: showOBE ? "block" : "none",
            }}
          >
            <Link
              className={
                props.subCurrentTab === 4.1
                  ? "dropdown-item active"
                  : "dropdown-item "
              }
              to={"/classroom/clos?id=" + props.classId}
              aria-expanded="true"
              onClick={() => {
                props.setCurrentTab(4);
                props.setSubCurrentTab(4.1);
                setShowOBE(false);
                setShowResource(false);
              }}
            >
              {ploBase === '1' ?props.plosName:props.closName}
            </Link>

            {ploBase === "1" ? (
              <></>
            ) : (
              <Link
                className={
                  props.subCurrentTab === 4.2
                    ? "dropdown-item active"
                    : "dropdown-item "
                }
                to={"/classroom/cloattainment?id=" + props.classId}
                aria-expanded="true"
                onClick={() => {
                  props.setCurrentTab(4);
                  props.setSubCurrentTab(4.2);
                  setShowOBE(false);
                  setShowResource(false);
                }}
              >
                {props.closName} Attainment
              </Link>
            )}
            <Link
              className={
                props.subCurrentTab === 4.3
                  ? "dropdown-item active"
                  : "dropdown-item "
              }
              to={"/classroom/ploattainment?id=" + props.classId}
              aria-expanded="true"
              onClick={() => {
                props.setCurrentTab(4);
                props.setSubCurrentTab(4.3);
                setShowOBE(false);
                setShowResource(false);
              }}
            >
              {props.plosName} Attainment
            </Link>

            <Link
              className={
                props.subCurrentTab === 4.4
                  ? "dropdown-item active"
                  : "dropdown-item "
              }
              to={"/classroom/cqi?id=" + props.classId}
              aria-expanded="true"
              onClick={() => {
                props.setCurrentTab(4);
                props.setSubCurrentTab(4.4);
                setShowOBE(false);
                setShowResource(false);
              }}
            >
              CQI
            </Link>
          </div>
        </li>
      ) : (
        <></>
      )}

      <li className="nav-item">
        <Link
          className={props.current === 5 ? "nav-link active " : "nav-link"}
          to={"/classroom/forums?id=" + props.classId}
          role="tab"
          aria-selected="false"
          title=""
          onClick={() => {
            props.setCurrentTab(5);
            props.setSubCurrentTab(5);
            setShowOBE(false);
            setShowResource(false);
          }}
        >
          Discussion Forums
        </Link>
      </li>
    </ul>
  );
}

export default ClassRoomTopMenu;
