import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import Chart from "react-apexcharts";

const attApi = axiosInstance;

function ClosAttainment(props) {
  const {  user } = useContext(UserContext);
  const classId = props.classId;

  const [gOptions, setGOptions] = useState({});
  const [gSeries, setGSeries] = useState([]);

  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  const columns = [
    {
      name: user.cloName,
      sortable: true,
      accessor: "code",
      selector: (row) => row.code,
      width: "100px",
    },
    {
      name: "Description",
      accessor: "desc",
      selector: (row) => <div className="text-wrap mt-2 mb-2">{row.desc}</div>,
      sortable: true,
    },
    {
      name: <div>My Score</div>,
      selector: (row) => row.myvalue,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Highest Score</div>,
      selector: (row) => row.max,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Lowest Score</div>,
      selector: (row) => row.min,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Average Score</div>,
      selector: (row) => row.avg,
      sortable: true,
      width: "100px",
    },
  ];
  
  useEffect(() => {
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classcloattainment?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);

          const cats = Object.keys(res.data).map(function (key) {
            return res.data[key].code;
          });
          const series1 = Object.keys(res.data).map(function (key) {
            return res.data[key].myvalue;
          });
          const series2 = Object.keys(res.data).map(function (key) {
            return res.data[key].max;
          });
          const series3 = Object.keys(res.data).map(function (key) {
            return res.data[key].min;
          });
          const series4 = Object.keys(res.data).map(function (key) {
            return res.data[key].avg;
          });

          setGOptions({
            chart: {
              id: "student-clo",
            },
            xaxis: {
              categories: cats,
            },
            yaxis: {
              min: 0,
              max: 100,
            },
            noData: {
              text: "There's no data",
              align: "center",
              verticalAlign: "middle",
              offsetX: 0,
              offsetY: 0,
            },
            annotations: {
              yaxis: [
                {
                  y: props.perMarks,
                  borderColor: "#f44336",
                  label: {
                    borderColor: "#f44336",
                    style: {
                      color: "#fff",
                      background: "#f44336",
                    },
                    text: "Required Student Acheivement Threshold",
                  },
                },
              ],
            },
          });

          setGSeries([
            {
              name: "My Score",
              data: series1,
            },
            {
              name: "Maximun",
              data: series2,
            },
            {
              name: "Minimum",
              data: series3,
            },
            {
              name: "Average",
              data: series4,
            },
          ]);
        }
      });
    }
  }, [classId, props.perMarks]);

  return (
    <div>
      <h3>{user.cloBName} Attainment</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
        fixedHeader
        highlightOnHover
        striped
      />
      <br />
      {classData.length === 0 ? (
        <></>
      ) : (
        <Chart
          options={gOptions}
          series={gSeries}
          type="bar"
          width="100%"
          height="400px"
        />
      )}
    </div>
  );
}

export default ClosAttainment;
