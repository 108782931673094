import React, { useContext, useEffect, useState } from "react";
import TeachingPlanCard from "../../components/TeachingPlanCard";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function TeachingPlan(props) {
  const { user } = useContext(UserContext);
  const classId = props.classId;
  const ploBase = props.ploBase;
  const [classData, setClassData] = useState([]);

  useEffect(() => {
    if (classId !== undefined) {
      attApi.get("/classplans?addAttachment=1&id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
        }
      });
    }
  }, [classId]);

  return (
    <div>
      <h3>
        {ploBase === "1" ? "Milestone / Deliverable Plan" : "Teaching Plan"}
      </h3>
      <p>
        Total <b>{classData.length}</b> items.
      </p>

      {classData.length === 0 ? (
        <></>
      ) : (
        classData.map(function (d, index) {
          return (
            <TeachingPlanCard data={d} key={index} cloName={user.cloName} />
          );
        })
      )}
    </div>
  );
}

export default TeachingPlan;
