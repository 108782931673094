import React from "react";

function Announcement(props) {
  return (
    <div>
      <div className="card mb-24 mr-12 ml-12">
        <div className="card-body">
          <h4>{props.data.name}</h4>
          Dated: {props.data.created_at}
          <div className="mt-36">
            <div dangerouslySetInnerHTML={{ __html: props.data.value }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
