import React, { useCallback, useContext, useEffect, useState } from "react";
import axiosInstance from "../../helper/axios";
import Modal from "react-modal";
import DataTable from "react-data-table-component";
import { UserContext } from "../../components/UserContext";
import DataLoading from "../../components/DataLoading";
import FilterComponent from "../../components/FilterComponent";
import { Link,  useNavigate } from "react-router-dom";

const attApi = axiosInstance;
const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px",
  },
};
Modal.setAppElement("#root");

function View(props) {
  let navigate = useNavigate();

  const classId = props.classId;
  const [classData, setClassData] = useState({ activities: [] });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenNotes, setIsOpenNotes] = React.useState(false);
  const [modalIsOpenUpload, setIsOpenUpload] = React.useState(false);
  const { notify } = useContext(UserContext);
  const [showDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSNote, setCurrentSNote] = useState("");
  const [currentSNoteId, setCurrentSNoteId] = useState(0);
  const [currentSNoteError, setCurrentSNoteError] = useState("");
  const [filterText, setFilterText] = React.useState("");
  const [uploadDesc, setUploadDesc] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const filteredItems = classData.activities.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.type.toLowerCase().includes(filterText.toLowerCase())
  );
  function setPreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  }
  const [values, setValues] = useState([]);
  const rowPreExpanded = (row) => row.expanded;
  const customStyles2 = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  const detailColumn = [
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: "Question",
      sortable: true,
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.question}</div>
      ),
    },
    {
      name: "OBE Weight",
      sortable: true,
      selector: (row) => row.weight,
    },
    {
      name: "Max Marks",
      sortable: true,
      selector: (row) => row.maxmarks,
    },
    {
      name: "Obtained Marks",
      sortable: true,
      selector: (row) => row.obtained,
    },
    {
      name: "CLOs",
      sortable: true,
      selector: (row) => row.cloStr,
    },
  ];
  const columns = [
    {
      name: "Date",
      sortable: true,
      selector: (row) => row.date,
    },
    {
      name: "Name",
      accessor: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      accessor: "type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Total Marks",
      accessor: "total",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "Obtained Marks",
      accessor: "obtained",
      selector: (row) => row.obtained,
      sortable: true,
    },
  ];

  const columnsAtt = [
    {
      name: "#",
      selector: (row, index, id) => index + 1,
      width: "40px",
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => row.attendance_date,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Present",
      accessor: "present",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">
          {row.present === "1" ? "Yes" : "No"}
        </div>
      ),
      sortable: true,
    },
  ];

  function openModalUpload(rid, desc, filename, filepath) {
    setCurrentSNoteId(rid);
    setUploadDesc(desc);
    setFileName(filename);
    setFilePath(filepath);
    setIsOpenUpload(true);
  }

  function closeModalUpload() {
    setIsOpenUpload(false);
  }

  function openModalNotes(rid, notes) {
    setCurrentSNoteId(rid);
    setCurrentSNote(notes === null ? "" : notes);
    setIsOpenNotes(true);
  }

  function closeModalNotes() {
    setIsOpenNotes(false);
  }
  function openModal() {
    attApi.get("/classattendance?id=" + classId).then((res) => {
      if (res.status === 200) {
        setValues(res.data.attendance);
      }
    });
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const getData = useCallback(() => {
    setLoading(true);
    attApi.get("/classview?id=" + classId).then((res) => {
      if (res.status === 200) {
        setClassData(res.data);
        setLoading(false);
      }
    });
  }, [classId]);
  useEffect(() => {
    if (classId !== undefined) {
      getData();
    }
  }, [classId, getData]);

  const solveAssignment = (e,aid) => {
    e.preventDefault();
    attApi
      .post("/takeassigment?id=" + aid)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            navigate("/quiz?id="+res.data.quizId)
          } else {
            return notify(res.data.message, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setCurrentSNoteError("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setCurrentSNoteError(err.response.data.message);
        } else {
          setCurrentSNoteError(err.message);
        }
      });
  };
  const ExpandedComponent = ({ data }) => {
    return (
      <div className="bg-light">
        {showDetail ? (
          <div className=" p-2 pl-5">
            {data.subActivities.length > 0 ? (
              <>
                <h5>Detail</h5>
                <DataTable
                  columns={detailColumn}
                  data={data.subActivities}
                  dense
                  customStyles={customStyles2}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        {(data.tRemarks === null || data.tRemarks === "") &&
        data.tUpload === null ? (
          <></>
        ) : (
          <div className="row p-2 pl-5">
            {data.tUpload === null ? (
              <></>
            ) : (
              <div className="col-md-6">
                <b>Teacher: </b>
                <a
                  href={data.tUpload.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.tUpload.name}
                </a>
                <br />
                {data.tUpload.description}
              </div>
            )}
            {data.tRemarks === null ||
            data.tRemarks === "" ||
            data.tRemarks === "-" ? (
              <></>
            ) : (
              <div className="col-md-6">
                <b>Teacher Remarks: </b>
                {data.tRemarks}
              </div>
            )}
          </div>
        )}
        {data.uUpload !== null || data.aUpload !== false ? (
          <>
            {data.fromDate !== "" ? (
              <p className="pt-2 pl-5">
                <b>Submission Date: </b>
                {data.fromDate} - {data.toDate}
              </p>
            ) : (
              <></>
            )}
            <div className="row pl-5 pb-2">
              {data.uUpload === null ? (
                <></>
              ) : (
                <div className="col-md-6">
                  <b>Student: </b>
                  <a
                    href={data.uUpload.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.uUpload.name}
                  </a>
                  <br />
                  {data.uUpload.description}
                </div>
              )}
              {data.aUpload === true ? (
                <div className="col-md-6">
                  {data.soleveAssignment === true ? (
                    <>
                      {data.allowSolveAssignment === true ? (
                        <>
                          {data.quizId === null ? (
                            <span
                              className="btn btn-primary mb-2 mr-2"
                              role="button"
                              onClick={(e) => solveAssignment(e,data.id)}
                            >
                              Solve {data.type}
                            </span>
                          ) : (
                            <Link
                              className="btn btn-primary mb-2 mr-2"
                              role="button"
                              to={"/quiz?id=" + data.quizId}
                            >
                              Solve {data.type}
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {data.takenSolveAssignment === true ? (
                            <span className="btn btn-light mb-2 mr-2">
                              {data.type} Submitted
                            </span>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <span
                      className="btn btn-primary mb-2 mr-2"
                      role="button"
                      onClick={() => {
                        openModalUpload(
                          data.remarksId,
                          data.uUpload.description,
                          data.uUpload.name,
                          data.uUpload.path
                        );
                      }}
                    >
                      Upload File
                    </span>
                  )}

                  <span
                    className="btn btn-success mb-2"
                    role="button"
                    onClick={() => {
                      openModalNotes(data.remarksId, data.sremarks);
                    }}
                  >
                    Student Notes
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        {data.uQuestion.length === 0 ? (
          <></>
        ) : (
          <>
            {Object.keys(data.uQuestion).map(function (key) {
              let d = data.uQuestion[key];
              return (
                <div
                  className="pl-5 pb-2"
                  key={key}
                  dangerouslySetInnerHTML={{ __html: d }}
                />
              );
            })}
          </>
        )}
        {data.sremarks === null || data.sremarks === "" ? (
          <></>
        ) : (
          <div className="p-2 pl-5">
            <b>Student Notes: </b>
            {data.sremarks}
          </div>
        )}
      </div>
    );
  };
  function handelNoteChange(event) {
    setCurrentSNote(event.target.value);
  }
  function updateNotes(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("notes", currentSNote);

    attApi
      .post("/classactivitynotes?id=" + currentSNoteId, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            getData();
            closeModalNotes();
          } else {
            let messages = res.data.message;
            messages.map(function (key) {
              return notify(key, "error");
            });
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setCurrentSNoteError("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setCurrentSNoteError(err.response.data.message);
        } else {
          setCurrentSNoteError(err.message);
        }
      });
  }
  function updateAttachment(e) {
    e.preventDefault();
    var formData = new FormData();
    formData.append("description", uploadDesc);
    formData.append("file", selectedFile);
    attApi
      .post("/classactivityupload?id=" + currentSNoteId, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            getData();
            closeModalUpload();
          } else {
            return notify(res.data.message, "error");
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setCurrentSNoteError("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setCurrentSNoteError(err.response.data.message);
        } else {
          setCurrentSNoteError(err.message);
        }
      });
  }
  return (
    <div>
      <div className="row">
        <div className="col col-md-4">
          <b>GPA Score</b> :{classData.gpa}
        </div>
        <div className="col col-md-4">
          <b>Grade</b> : {classData.grade}
          <br />
        </div>
        <div className="col col-md-4">
          <b>Attendance</b> : {classData.present_percentage}% (
          {classData.total_present} / {classData.total_lecture})
          <button
            className="btn btn-primary pull-right"
            data-toggle="modal"
            data-target=".attendance-detail"
            onClick={openModal}
          >
            See Detail
          </button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div className="modal-header">
              <h4 id="myLargeModalLabel" className="modal-title">
                Attendance Detail
              </h4>
            </div>
            <div className="modal-body">
              <DataTable
                columns={columnsAtt}
                data={values}
                highlightOnHover
                striped
                customStyles={customStyles2}
              />

              <button
                type="button"
                className=" btn btn-danger mt-2"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </div>

      {classData.remarks === null || classData.remarks === "" ? (
        <></>
      ) : (
        <>
          <p>
            <b>Teacher Remarks: </b>
            {classData.remarks}
          </p>
        </>
      )}
      <hr />

      <div className=" float-right">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          filterText={filterText}
        />
      </div>
      <h3>Activities</h3>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id="loginformstudent-rememberme"
          className="custom-control-input"
          checked={showDetail}
          onChange={(e) => {
            setShowDetail(e.target.checked);
          }}
        />
        <label
          className="custom-control-label"
          htmlFor="loginformstudent-rememberme"
        >
          Show Detail
        </label>
      </div>

      {classData.activities.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredItems}
          highlightOnHover
          customStyles={customStyles2}
          striped
          progressComponent={<DataLoading />}
          progressPending={loading}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowExpanded={rowPreExpanded}
          expandableRowDisabled={false}
        />
      ) : (
        <></>
      )}
      <Modal
        isOpen={modalIsOpenNotes}
        onRequestClose={closeModalNotes}
        style={customStyles}
      >
        <div className="modal-header">
          <h4 id="myLargeModalLabel" className="modal-title">
            Student Notes
          </h4>
        </div>
        <div className="modal-body">
          <textarea
            id="uniclassroomremarks-student_notes"
            className="form-control"
            name="Uniclassroomremarks[student_notes]"
            rows="6"
            aria-invalid="false"
            onChange={handelNoteChange}
            value={currentSNote}
          ></textarea>
          <p className="required">{currentSNoteError}</p>
          <button
            type="button"
            className=" btn btn-success mt-3 mr-2"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={updateNotes}
          >
            Save
          </button>
          <button
            type="button"
            className=" btn btn-danger mt-3"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeModalNotes}
          >
            Close
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenUpload}
        onRequestClose={closeModalUpload}
        style={customStyles}
      >
        <div className="modal-header">
          <h4 id="myLargeModalLabel" className="modal-title">
            Upload File
          </h4>
        </div>
        <div className="modal-body">
          <input
            type="file"
            name={"file"}
            id={"file"}
            onChange={(e) => {
              setPreview(e);
            }}
          />
          {fileName === "" ? (
            <></>
          ) : (
            <div className="mt-3">
              <a href={filePath} target="_blank" rel="noopener noreferrer">
                <span className="glyphicon glyphicon-paperclip"> {fileName}</span>
              </a>
            </div>
          )}

          <p className="mt-2">
            1. Allowed file types are xls, xlsx, doc, docs, pdf, jpg, jpeg, ppt,
            pptx, zip, rar and png. <br />
            2. Max upload size is 25MB
          </p>
          <div className="form-group field-attachment-description">
            <label className="control-label" htmlFor="attachment-description">
              Description
            </label>
            <input
              type="text"
              id="attachment-description"
              className="form-control"
              maxLength="500"
              value={uploadDesc}
              onChange={(e) => {
                setUploadDesc(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className=" btn btn-success mt-3 mr-2"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={updateAttachment}
          >
            Save
          </button>
          <button
            type="button"
            className=" btn btn-danger mt-3"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeModalUpload}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default View;
