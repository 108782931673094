import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import { Link } from "react-router-dom";

const attApi = axiosInstance;

function Fee(props) {
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState({ paidVouchers: [], unpaidVouchers: [] });
  const [error, setError] = useState("");

  const downloadVoucehr = (id) => {
    const axiosConf = {
      responseType: "arraybuffer",
    };
    setLoading(true);
    attApi
      .get("/feevoucher?pdf=true&id=" + id, axiosConf)
      .then((response) => {
        if (response.status === 200) {
          let fileName = "Fee Voucer.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    setTitle("Fee Information");
    setOperation("Fee Information");
    setPLink("");
    attApi
      .get("/fee")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            setFee(res.data.data);
          } else {
            setError(res.data.message);
          }

          //setCards(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink, notify]);
  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <div>
          {error !== "" ? (
            <p className="required">{error}</p>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div className="card-body text-center">
                      <i className="i-Money-Bag"></i>
                      <div className="content  card-data">
                        <p className="text-muted mt-2 mb-0">Total Amount</p>
                        <p className="lead text-primary text-24 mb-2">
                          {fee.currency}: {fee.totalAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div className="card-body text-center">
                      <i className="i-Money-2"></i>
                      <div className="content  card-data">
                        <p className="text-muted mt-2 mb-0">Paid Amount</p>
                        <p className="lead text-primary text-24 mb-2">
                          {fee.currency}: {fee.totalPaid}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                    <div className="card-body text-center">
                      <i className="i-Financial"></i>
                      <div className="content  card-data">
                        <p className="text-muted mt-2 mb-0">
                          Outstanding Amount
                        </p>
                        <p className="lead text-primary text-24 mb-2 ">
                          {fee.currency}: {fee.totalOutstanding}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {fee.unpaidVouchers.length > 0 ? (
                <>
                  <h4>Outstanding Fee Payments</h4>
                  {fee.unpaidVouchers.map(function (d, index) {
                    return (
                      <div className="card mb-2" key={index}>
                        <div className="card-body">
                          <h4>
                            {d.name} [{d.session}]
                            <span className="figure2 pull-right">
                              {d.currency} :{d.amount}
                            </span>
                          </h4>
                          <div>
                            Voucher Date:{" "}
                            <span className="figure3 ml-2">
                              {d.voucher_date}{" "}
                            </span>
                            <br />
                            Due Date:{" "}
                            <span className="figure3  ml-2">{d.due_date}</span>
                            <span className="pull-right">
                              <Link
                                className="btn btn-outline-primary mr-1"
                                to={"/feevoucher?id="+d.id}
                              >
                                View Voucher
                              </Link>
                              <span
                                role="button"
                                className="btn btn-outline-primary  mr-1"
                                onClick={() => downloadVoucehr(d.id)}
                              >
                                Download Voucher
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {fee.paidVouchers.length > 0 ? (
                <>
                  <h4>Paid Fee</h4>
                  {fee.paidVouchers.map(function (d, index) {
                    return (
                      <div className="card mb-2" key={index}>
                        <div className="card-body">
                          <h4>
                            {d.name} [{d.session}]
                            <span className="figure2 pull-right">
                              {d.currency} :{d.amount}
                            </span>
                          </h4>
                          <div>
                            Voucher Date:{" "}
                            <span className="figure3 ml-2">
                              {d.voucher_date}{" "}
                            </span>
                            <br />
                            Due Date:{" "}
                            <span className="figure3  ml-2">{d.due_date}</span>
                            <span className="pull-right">
                            <Link
                                className="btn btn-outline-primary mr-1"
                                to={"/feevoucher?id="+d.id}
                              >
                                View Voucher
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Fee;
