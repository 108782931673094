import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";
import ObeTopMenu from "../../components/ObeTopMenu";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Ploattainment(props) {
  const { user, setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [ploData, setPloData] = useState({
    data: {},
    ploList: [],
    AvgData: {},
  });
  useEffect(() => {
    setLoading(true);
    setTitle(user.ploPName + " Attainment");
    setOperation(user.ploPName + " Attainment");
    setPLink("");
    attApi
      .get("/ploattainment")
      .then((res) => {
        if (res.status === 200) {
          setPloData(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setTitle, setOperation, setPLink]);

  return (
    <>
      <ObeTopMenu current={2} />
      {loading ? (
        <DataLoading />
      ) : (
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered table-stripped table-condensed table-responsive">
              <thead>
                <tr>
                  <th>Course</th>
                  {Object.keys(ploData.ploList).map(function (key) {
                    return (
                      <th key={key} className="text-center">
                        {ploData.ploList[key].code}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.keys(ploData.data).map(function (key2) {
                  let dd = ploData.data[key2];
                  return Object.keys(dd).map(function (key) {
                    let d = dd[key];
                    let semShow = d.semestershow;
                    if (key > 0) {
                      if (dd[key - 1]) {
                        if (dd[key - 1].semestershow === d.semestershow) {
                          semShow = "";
                        }
                      }
                    }
                    return (
                      <React.Fragment key={key}>
                        {semShow === "" ? (
                          <></>
                        ) : (
                          <tr key={key}>
                            <td colSpan={6}>
                              <b>{semShow}</b>
                            </td>
                          </tr>
                        )}
                        <tr key={d}>
                          <td>{d.course}</td>
                          {Object.keys(ploData.ploList).map(function (key) {
                            let plocol = ploData.ploList[key].col;
                            return (
                              <td key={key} className="text-center">
                                {d[plocol]}
                              </td>
                            );
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  });
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={ploData.ploList.length + 1}></td>
                </tr>
                {Object.keys(ploData.AvgData).map(function (key) {
                  let d = ploData.AvgData[key];
                  return (
                    <React.Fragment key={key}>
                      <tr key={d}>
                        <td>Average</td>
                        {Object.keys(ploData.ploList).map(function (key) {
                          let plocol = ploData.ploList[key].col;
                          return (
                            <td key={key} className="text-center">
                              {d[plocol]}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Ploattainment;
