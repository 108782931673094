import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";

function ClassRoomCard(props) {
  const { user } = useContext(UserContext);
  return (
    <div className="col-lg-4 col-xl-4 mt-3 class-node">
      <div className="card">
        <div className="card-body">
          <div className="user-profile mb-4">
            <div className="ul-widget-card__user-info">
              <img
                className="profile-picture avatar-lg mb-2"
                src={props.data.teacherdp}
                alt=""
              />
              <p className="m-0 text-16">
                <Link to={"/classroom?id=" + props.data.id}>
                  {props.data.course}
                </Link>
              </p>
              <p className="text-muted m-0">
                <Link to={"/classroom?id=" + props.data.id}>
                  {props.data.name}
                </Link>
                <br />
                {props.data.teacher}
                <br />
                {props.data.semester}
              </p>
            </div>
          </div>

          <div className="ul-widget-card__full-status mb-3">
            {props.data.plo_base === "1" ? (
              <></>
            ) : (
              <Link
                className="btn btn-primary"
                style={{ color: "#fff" }}
                to={"/classroom/cloattainment?id=" + props.data.id}
              >
                {user.cloName} Attainment
              </Link>
            )}
            <Link
              className="btn btn-warning"
              to={"/classroom/ploattainment?id=" + props.data.id}
            >
              {user.ploName} Attainment
            </Link>
          </div>
          <div className="dropdown-divider"></div>
          <div className="ul-widget-card__rate-icon">
            <span>
              <i className="i-Add-File text-warning"></i>
              <Link to={"/classroom/clos?id=" + props.data.id}>
                {props.data.clocount}{" "}
                {props.data.plo_base === "1" ? user.ploName : user.cloName}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassRoomCard;
