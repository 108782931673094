import React from "react";
import CalanderEvent from "./CalanderEvent";

function CalanderEvents(props) {
  
  return (
    <>
      {props.data.length === 0 ? (
        <></>
      ) : (
        <div>
          {Object.keys(props.data).map(function (key) {
            return props.data[key].slot === props.slotId ? (
              <CalanderEvent key={key} cdata={props.data[key]} slotName={props.slotName} showDate={props.showDate}></CalanderEvent>
            ) : (
              <React.Fragment key={key}></React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
}

export default CalanderEvents;
