import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";
import ObeTopMenu from "../../components/ObeTopMenu";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Cloattainment(props) {
  const { user, setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [cloData, setCloData] = useState([]);
  useEffect(() => {
    setLoading(true);
    setTitle(user.cloPName + " Attainment");
    setOperation(user.cloPName + " Attainment");
    setPLink("");
    attApi
      .get("/cloattainment")
      .then((res) => {
        if (res.status === 200) {
          setCloData(res.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setTitle, setOperation, setPLink]);

  return (
    <>
      <ObeTopMenu current={1} />
      {loading ? (
        <DataLoading />
      ) : (
        <div className="card">
          <div className="card-body">
            <table className="table table-bordered table-stripped table-condensed table-responsive">
              <thead>
                <tr>
                  <th>{user.cloName}</th>
                  <th>{user.cloName} Description</th>
                  <th>{user.ploPName}</th>
                  <th className="text-center">Required Percentage</th>
                  <th className="text-center">{user.cloName} Attainment</th>
                </tr>
              </thead>
              <tbody>
                {cloData.map(function (d, index) {
                  return (
                    <React.Fragment key={index}>
                      {index === 0 || cloData[index - 1].name !== d.name ? (
                        <tr key={index + 1000}>
                          <td colSpan={6}>
                            <b>{d.name}</b>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                      <tr key={index + 2000}>
                        <td>{d.clo_code}</td>
                        <td>{d.clo_desc}</td>
                        <td>{d.plo}</td>
                        <td className="text-center"> {d.per} </td>
                        <td
                          className={
                            d.clo_val < d.per
                              ? "required text-center "
                              : "text-center"
                          }
                        >
                          {d.clo_val}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Cloattainment;
