import React, { useContext, useState } from "react";
import axiosInstance from "../helper/axios";
import { UserContext } from "./UserContext";

const attApi = axiosInstance;
function Question(props) {
  
  const { notify } = useContext(UserContext);
  const [removeImg, setRemoveImg] = useState("0");
  const [selectedImage, setSelectedImage] = useState();
  const [review, setReview] = useState(false);
  function setPreview(e) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  }
  let d = props.data;
  let ansContent;
  if (d.type === "T") {
    ansContent = (
      <textarea
        className="form-control"
        maxLength="900"
        rows="2"
        name={"answer[" + d.id + "]"}
        id={"answer-" + d.id}
        value={props.ansData["answer-" + d.id] || ""}
        onChange={props.handleChange}
      />
    );
  } else if (d.type === "R") {
    ansContent = (
      <div className="form-group">
        <div className="form-group field-surveydatadtl-1971-answer">
          <div id={"answer-" + d.id} role="radiogroup">
            {Object.keys(d.choices).map(function (key2) {
              let choice = d.choices[key2];
              return (
                <label
                  key={key2}
                  className="radio radio-primary mr-3 mb-3"
                  style={{ display: "block" }}
                >
                  <input
                    type="radio"
                    name={"answer[" + d.id + "]"}
                    id={"answer-" + d.id}
                    value={choice}
                    checked={choice === props.ansData["answer-" + d.id]}
                    onChange={props.handleChange}
                  />
                  <span>{choice}</span>
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (d.type === "M") {
    ansContent = (
      <div className="form-group">
        <div className="form-group field-surveydatadtl-1971-answer">
          <div id={"answer-" + d.id} role="radiogroup">
            {Object.keys(d.choices).map(function (key2) {
              let choice = d.choices[key2];
              let isChecked = false;

              if (props.ansData["answer-" + d.id]) {
                let ansr = Object.assign([], props.ansData["answer-" + d.id]);
                if (ansr.includes(choice)) {
                  isChecked = true;
                }
              }

              return (
                <label
                  key={key2}
                  className="checkbox radio-primary mr-3 mb-3"
                  style={{ display: "block" }}
                >
                  <input
                    type="checkbox"
                    name={"answer[" + d.id + "]"}
                    value={choice}
                    id={"answer-" + d.id}
                    checked={isChecked}
                    onChange={props.handleCheckChange}
                  />
                  <span>{choice}</span>
                  <span className="checkmark"></span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (d.type === "U") {
    ansContent = (
      <>
        <input
          type="file"
          name={"ans_img[" + d.id + "]"}
          id={"ans_img-" + d.id}
          onChange={(e) => {
            props.handleImageChange(e);
            setRemoveImg("0");
            d.ans_img = "";
            setPreview(e);
          }}
          accept="image/png, image/jpeg, application/pdf"
        />
        <p className="mt-2">
          It is must to add description below about the image/file you attached
          above!
        </p>
        {d.ans_img === null || d.ans_img === "" ? (
          <></>
        ) : (
          <>
            <img src={d.ans_img} alt="" className="img-responsive mb-2" />
            <button
              className="btn btn-danger btn-xs mb-3"
              onClick={() => {
                setRemoveImg("1");
                d.ans_img = "";
              }}
            >
              <i className="i-Remove"></i> Remove File
            </button>
          </>
        )}

        {selectedImage ? (
          <>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt=""
              className="img-responsive mb-2"
            />
            <button
              className="btn btn-danger btn-xs mb-3"
              onClick={() => {
                setSelectedImage();
                setRemoveImg("1");
              }}
            >
              <i className="i-Remove"></i> Remove File
            </button>
          </>
        ) : (
          <></>
        )}
        <textarea
          className="form-control"
          maxLength="900"
          rows="2"
          name={"answer[" + d.id + "]"}
          id={"answer-" + d.id}
          value={props.ansData["answer-" + d.id] || ""}
          onChange={props.handleChange}
        />
      </>
    );
  }

  async function saveQuestion(e) {
    e.preventDefault();
    var formData = new FormData();
    let val =
      d.type === "M"
        ? props.ansData["answer-" + d.id].join(";")
        : props.ansData["answer-" + d.id];
    formData.append("answer", val);

    if (d.type === "U") {
      let selectedFile = props.ansData["ans_img-" + d.id];

      if (removeImg === "1") {
        formData.append("removeimg", removeImg);
        setRemoveImg("0");
      } else {
        formData.append("ans_img", selectedFile);
      }
    }

    attApi
      .post("/quizanswer?id=" + props.quizId + "&qid=" + d.id, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.sucess === true) {
            notify(res.data.message, "success");
            d.answer = props.ansData["answer-" + d.id];
            if(review === true){
                console.log("i am here")
                props.setReview(true);
                props.reloadQuiz();
                setReview(false);
            }
          } else {
            notify(res.data.message, "error");
            props.setErrorTxt(res.data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          props.setErrorTxt("Invalid arugument sent");
        } else if (err.response.status === 403) {
          props.setErrorTxt(err.response.data.message);
        } else {
          props.setErrorTxt(err.message);
        }
      });
  }

  function saveSubmit(e) {
    saveQuestion(e);
    props.submitQuiz(e);
  }
  
  function saveReview(e){
    props.setReview(true)
   // saveQuestion(e)
  }
  function saveBack(e) {
    saveQuestion(e);
    props.setCurrentQid(props.index - 1);
  }
  function saveNext(e) {
    saveQuestion(e);
    props.setCurrentQid(props.index + 1);
  }
  return (
    <div>
      <h4 style={{ color: "black" }}>
        Q{props.qid}. {props.data.question}
        <span className="badge pull-right">{props.qid}/{props.total}</span>
      </h4>

      {d.q_img === "" ? (
        <></>
      ) : (
        <img src={d.q_img} alt="Question" className="img-responsive mb-2" />
      )}
      <div
        className={
          props.errorArr[props.qid] ? "form-group  has-error" : "form-group"
        }
      >
        {ansContent}
        <div className="help-block">{props.errorArr[props.qid] || ""}</div>
      </div>
      <p>
        {props.qid > 1 ? (
          <button className="btn btn-outline-primary mr-2  mb-2" onClick={saveBack}>
            &lt; Save &amp; Back
          </button>
        ) : (
          <></>
        )}

        <button className="btn btn-outline-success mr-2  mb-2" onClick={saveQuestion}>
          Save
        </button>
        {props.qid < props.total ? (
          <button className="btn btn-outline-primary mr-2  mb-2" onClick={saveNext}>
            Save &amp; Next &gt;
          </button>
        ) : (
          <></>
        )}
        {props.qid === props.total ? (
          <>
            <button className="btn btn-outline-primary mr-2  mb-2" onClick={saveReview}>
              Save &amp; Review
            </button>

            <button
              className="btn btn-outline-success mr-2 mb-2"
              onClick={saveSubmit}
            >
              Save &amp; Submit
            </button>
          </>
        ) : (
          <></>
        )}
      </p>
    </div>
  );
}

export default Question;
