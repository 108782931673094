import React, { useCallback, useContext, useEffect, useState } from "react";
import DataLoading from "../../components/DataLoading";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import { useSearchParams } from "react-router-dom";

const attApi = axiosInstance;

function FeeVoucher(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const vId = searchParams.get("id");

  const [voucherId, setVoucehrId] = useState(0);

  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState({ data: "" });
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    setTitle("Fee Voucher");
    setOperation("Fee Information");
    setPLink([{ name: "Fee Information", link: "/fee" }]);
    setVoucehrId(vId);
  }, [setTitle, setOperation, setPLink, vId]);

  const getVoucherData = useCallback(async () => {
    setLoading(true);
    await attApi
      .get("/feevoucher?id=" + voucherId)
      .then((res) => {
        if (res.status === 200) {
          setFee(res.data);
          console.log(res.data);
        }
      })
      .catch((err) => {
        setError(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [voucherId]);

  useEffect(() => {
    if (voucherId !== 0) {
      getVoucherData();
    }
  }, [voucherId, getVoucherData]);
  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <div>
          {error !== "" ? (
            <p className="required">{error}</p>
          ) : (
            <div className="card mt-3">
              <div className="card-body">
                <div dangerouslySetInnerHTML={{ __html: fee.data.data }} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FeeVoucher;
