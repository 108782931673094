import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import DataLoading from "../../components/DataLoading";

import ObeTopMenu from "../../components/ObeTopMenu";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;
function PloAttainGraph(props) {
  const { user, setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [gOptions, setGOptions] = useState({});
  const [gSeries, setGSeries] = useState([]);

  const [ploData, setPloData] = useState({
    head: "",
    footer: "",
    per: 50,
  });
  useEffect(() => {
    setLoading(true);
    setTitle(user.ploPName + " Attainment Graph");
    setOperation(user.ploPName + " Attainment Graph");
    setPLink("");
    attApi
      .get("/ploattainmentgraph")
      .then((res) => {
        if (res.status === 200) {
          const cats = Object.keys(res.data.data).map(function (key) {
            return res.data.data[key].plo_code;
          });
          const series = Object.keys(res.data.data).map(function (key) {
            return res.data.data[key].value;
          });

          setPloData({
            head: res.data.head,
            footer: res.data.footer,
          });

          setGOptions({
            chart: {
              id: "student-plo",
            },
            xaxis: {
              categories: cats,
            },
            yaxis: {
              min: 0,
              max: 100,
            },
            noData: {
              text: "There's no data",
              align: "center",
              verticalAlign: "middle",
              offsetX: 0,
              offsetY: 0,
            },
            annotations: {
              yaxis: [
                {
                  y: res.data.per,
                  borderColor: "#f44336",
                  label: {
                    borderColor: "#f44336",
                    style: {
                      color: "#fff",
                      background: "#f44336",
                    },
                    text: "Required Student Acheivement Threshold",
                  },
                },
              ],
            },
          });

          setGSeries([
            {
              name: "",
              data: series,
            },
          ]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setTitle, setOperation, setPLink]);

  return (
    <>
      <ObeTopMenu current={3} />
      {loading ? (
        <DataLoading />
      ) : (
        <div>
          <p className="mt-3">
            <strong>{ploData.head}</strong>
          </p>
          <Chart
            options={gOptions}
            series={gSeries}
            type="bar"
            width="100%"
            height="400px"
          />

          <strong>{ploData.footer}</strong>
        </div>
      )}
    </>
  );
}

export default PloAttainGraph;
