import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Attachments(props) {
  const { setTitle, user } = useContext(UserContext);
  const classId = props.classId;

  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState({});

  const columns = [
    {
      name: "File Name",
      accessor: "filename",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">
          <a href={row.path} target="_blank" rel="noopener noreferrer">
            {row.filename}
          </a>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Creation Date",
      accessor: "cdate",
      selector: (row) => <div className="text-wrap mt-2 mb-2">{row.cdate}</div>,
      sortable: true,
    },
    {
      name: "Description",
      accessor: "description",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.description}</div>
      ),
      sortable: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (classId !== undefined) {
      attApi.get("/classresources?id=" + classId).then((res) => {
        if (res.status === 200) {
          setClassData(res.data);
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [classId, setTitle, user]);

  return (
    <div>
      <h3>Attachments</h3>
      <span className="float-left">
        Total <b>{classData.length}</b> items.
      </span>
      <DataTable
        columns={columns}
        data={classData}
        progressComponent={<DataLoading />}
        progressPending={loading}
        fixedHeader
        highlightOnHover
        striped
      />
    </div>
  );
}

export default Attachments;
