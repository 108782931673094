import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alerts from "../components/Alerts";
import ClassRoomCard from "../components/ClassRoomCard";
import DataLoading from "../components/DataLoading";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";

const attApi = axiosInstance;
function Index(props) {
  const [loading, setLoading] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const { setTitle, setOperation, user, setPLink } = useContext(UserContext);
  const [classData, setClassData] = useState([]);
  useEffect(() => {
    setTitle("Ongoing Courses");
    setOperation("Ongoing Courses");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  const refreshData = ()=>{
    setLoading(true);
    attApi
      .get("/currentclasses")
      .then((res) => {
        if (res.status === 200) {
          setClassData(res.data.my);
          attApi.get("/alerts2").then((res) => {
            if (res.status === 200) {
              setAlertData(res.data);
            }
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <Alerts alertData={alertData} refreshData={refreshData} />
          {classData.length > 0 ? (
            
            <div className="row">
              {Object.keys(classData).map(function (key) {
                return (
                  <ClassRoomCard
                    key={key}
                    data={classData[key]}
                  ></ClassRoomCard>
                );
              })}
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6 col-12">
                <h5 className="mt-24 mb-48">Dear {user.name}</h5>
                <p>
                  Currently there is no registered course in ongoing semester.
                </p>
                <p>
                  Although you can see your course history{" "}
                  <Link to="/classrooms">Click Here</Link>
                </p>
              </div>
              <div className="col-md-6 col-12">
                <img src="/imgs/course.png" alt="Courses"  className="mt-5"/>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Index;
