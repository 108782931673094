import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./pages/Login";
import Cookies from "js-cookie";
import axiosInstance from "./helper/axios";
import { UserContext } from "./components/UserContext";
import Index from "./pages/Index";
import ProtectedRoutes from "./helper/ProtectedRoutes";
import Privacy from "./pages/Privacy";
import Sidemenu from "./components/Sidemenu";
import Breadcrumb from "./components/Breadcumb";
import Page404 from "./pages/Page404";
import Attendance from "./pages/Student/Attendance";
import Profile from "./pages/Student/Profile";
import UpdateProfile from "./pages/Student/UpdateProfile";
import ChangePassword from "./pages/Student/ChangePassword";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetupInterceptors from "./helper/SetupInterceptor";
import Cloattainment from "./pages/Student/Cloattainment";
import Ploattainment from "./pages/Student/Ploattainment";
import PloAttainGraph from "./pages/Student/PloAttainGraph";
import Obetranscript from "./pages/Student/Obetranscript";
import TimeTable from "./pages/Student/TimeTable";
import ClassRooms from "./pages/ClassRoom/ClassRooms";
import ClassRoom from "./pages/ClassRoom/ClassRoom";
import ClassForum from "./pages/ClassRoom/ClassForum";
import AdmitCard from "./pages/Student/AdmitCard";
import Survey from "./pages/Student/Survey";
import Videos from "./pages/Student/Videos";
import Video from "./pages/Student/Video";
import Quiz from "./pages/ClassRoom/Quiz";
import UpdateProfileImage from "./pages/Student/UpdateProfileImage";
import Announcements from "./pages/Announcements";
import Notifications from "./pages/Notifications";
import Register from "./pages/Student/Register";
import Fee from "./pages/Student/Fee";
import FeeVoucher from "./pages/Student/FeeVoucher";


const pingApi = axiosInstance;

function App() {

  const refreshUser = () =>{
    pingApi
      .get("/logedin")
      .then((res) => {
        if (res.status === 200) {
          if(res.data.loggedin === true){
            setUser(res.data.params)
          }
        }
      })
  }
  const logOut = () => {
    window.localStorage.clear("user");
    setUser(null);
  }
  function NavigateFunctionComponent(props) {
    // let navigate = useNavigate();
    const [ran, setRan] = useState(false);
    if (!ran) {
      SetupInterceptors(logOut);
      setRan(true);
    }
    return <></>;
  }

  let luser = window.localStorage.getItem("user");

  if (luser != null) {
    luser = JSON.parse(luser);
  }
  const [user, setUser] = useState(luser);
  const [title, setTitle] = useState('');
  const [operation, setOperation] = useState('');
  const [plink, setPLink] = useState([]);
  const [sideBarOpen, setSideBarOpen] = useState(true);

  const notify = (txt, type) => {
    if (type === 'success') {
      toast.success(txt)
    }
    else if (type === 'error') {
      toast.error(txt)
    }
    else if (type === 'info') {
      toast.info(txt)
    } else {
      toast(txt)
    }
  };
  useEffect(() => {
    let ss = Cookies.get("student-web-api");
    if (ss === undefined || ss === null || ss === "") {
      pingApi.get("ping").then((response) => {
        Cookies.set("student-web-api", response.data, {
          secure: true,
          sameSite: "None",
        });
      });
    }
  }, []);

  return (
    <>
      <UserContext.Provider value={{ user, setUser, setTitle, setOperation, setPLink, notify, logOut,refreshUser }}>
        <Router>
          {<NavigateFunctionComponent />}
          <div className={user ? "app-admin-wrap layout-sidebar-large" : ''}>

            <Header setSideBarOpen={setSideBarOpen} sideBarOpen={sideBarOpen} />
            <Sidemenu sideBarOpen={sideBarOpen} />
            <div className={user ? (sideBarOpen === true ? "main-content-wrap sidenav-open d-flex flex-column" : "main-content-wrap  d-flex flex-column") : ""}>
              <Breadcrumb title={title} operation={operation} plink={plink} />
              <div className="main-content">

                <ToastContainer />
                <Routes>
                  <Route path="/login" exact element={<Login />} />

                  <Route element={< ProtectedRoutes />}>
                    <Route path="/" exact element={<Index />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/updateprofile" element={<UpdateProfile />} />
                    <Route path="/updateprofileimage" element={<UpdateProfileImage />} />
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/attendance" element={<Attendance />} />
                    <Route path="/cloattainment" element={<Cloattainment />} />
                    <Route path="/ploattainment" element={<Ploattainment />} />
                    <Route path="/plograph" element={<PloAttainGraph />} />
                    <Route path="/transcriptobe" element={<Obetranscript />} />
                    <Route path="/timetable" element={<TimeTable />} />
                    <Route path="/classrooms" element={<ClassRooms />} />
                    <Route path="/classroom" element={<ClassRoom />} />
                    <Route path="/classroom/timetable" element={<ClassRoom />} />
                    <Route path="/classroom/forums" element={<ClassRoom />} />
                    <Route path="/classroom/clos" element={<ClassRoom />} />
                    <Route path="/classroom/media" element={<ClassRoom />} />
                    <Route path="/classroom/classplan" element={<ClassRoom />} />
                    <Route path="/classroom/attachments" element={<ClassRoom />} />
                    <Route path="/classroom/cloattainment" element={<ClassRoom />} />
                    <Route path="/classroom/ploattainment" element={<ClassRoom />} />
                    <Route path="/classroom/cqi" element={<ClassRoom />} />
                    <Route path="/classforum" element={<ClassForum />} />
                    <Route path="/admitcard" element={<AdmitCard />} />
                    <Route path="/survey" element={<Survey />} />
                    <Route path="/videos" element={<Videos />} />
                    <Route path="/video" element={<Video />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/announcement" element={<Announcements />} />
                    <Route path="/notification" element={<Notifications />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/fee" element={<Fee />} />
                    <Route path="/feevoucher" element={<FeeVoucher />} />
                  </Route>
                  <Route path='*' element={<Page404 />} />
                </Routes>
              </div>
              <div className="flex-grow-1"></div>
              <Footer />
            </div>
          </div>
        </Router>
      </UserContext.Provider>
    </>
  );
}

export default App;
