import React, { useContext } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../helper/axios";
import { UserContext } from "./UserContext";

const attApi2 = axiosInstance;
function Sidemenu(props) {
  const { user, notify } = useContext(UserContext);
  var date = new Date().getDate();
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();
  const date2 = date + "-" + month + "-" + year;

  function getTrans() {
    const axiosConf = {
      responseType: "arraybuffer",
    };
    notify("Please wait while file get downloaded!", "info");
    attApi2.get("/getgpatranscript", axiosConf).then((response) => {
      if (response.status === 200) {
        let fileName = "GPA Transcript.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
              encoding: "UTF-8",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    });
  }
  return (
    <>
      {user ? (
        <div className="side-content-wrap ">
          <div
            className={
              props.sideBarOpen === true
                ? "sidebar-left rtl-ps-none ps ps--active-y open"
                : "sidebar-left rtl-ps-none ps ps--active-y "
            }
            data-perfect-scrollbar=""
            data-suppress-scroll-x="true"
          >
            <ul className=" navigation-left">
              <li className="nav-item">
                <Link className="nav-item-hold" to="/">
                  <i className="nav-icon i-Home1"></i>
                  <span className="nav-text">Home</span>
                </Link>
                <div className="triangle"></div>
              </li>

              <li className="nav-item">
                <Link className="nav-item-hold" to="/classrooms">
                  <i className="nav-icon i-Book"></i>
                  <span className="nav-text">My Courses</span>
                </Link>
                <div className="triangle"></div>
              </li>

              <li className="nav-item">
                <Link className="nav-item-hold" to={"/timetable?date=" + date2}>
                  <i className="nav-icon i-Calendar-4"></i>
                  <span className="nav-text">Time Table</span>
                </Link>
                <div className="triangle"></div>
              </li>

              <li className="nav-item">
                <Link className="nav-item-hold" to="/attendance">
                  <i className="nav-icon i-Receipt-4"></i>
                  <span className="nav-text">Attendance</span>
                </Link>
                <div className="triangle"></div>
              </li>

              {user.showObeTranscript || user.showObeReports ? (
                <li className="nav-item" data-item="OBE" id="menu_OBE/AOL">
                  <Link
                    className="nav-item-hold"
                    to={
                      user.showObeReports ? "/cloattainment" : "/transcriptobe"
                    }
                  >
                    <i className="nav-icon i-Receipt-4"></i>
                    <span className="nav-text">{user.ObeName}</span>
                  </Link>
                  <div className="triangle"></div>
                </li>
              ) : (
                <></>
              )}
              {user.showGPATranscript ? (
                <li className="nav-item">
                  <span className="nav-item-hold" onClick={() => getTrans()}>
                    <i className="nav-icon i-Diploma-2"></i>
                    <span className="nav-text">Transcript - GPA</span>
                  </span>
                  <div className="triangle"></div>
                </li>
              ) : (
                <></>
              )}
              {user.showFeeMenu ? (
                <li className="nav-item">
                  <Link className="nav-item-hold" to={"/fee"}>
                  <i className="nav-icon i-Notepad"></i>
                  <span className="nav-text">Fee</span>
                </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          <div className="sidebar-overlay"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Sidemenu;
