import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
function CalanderEvent(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <div
        onClick={openModal}
        style={{ textAlign: "center", cursor: "pointer" }}
        className={"pb-1 pt-1 event event1"}
      >
        <b>{props.cdata.name}</b>
        <br />
        {props.cdata.teacher}
        {props.cdata.topic}
        {props.cdata.status === "" ? (
          <></>
        ) : (
          <>
            <br />
            <span className="badge badge-primary">{props.cdata.status}</span>
          </>
        )}
        {props.cdata.present === "" ? (
          <></>
        ) : (
          <>
            <br />
            <span className="badge badge-sucess">{props.cdata.present}</span>
          </>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={props.cdata.name}
      >
        <div className="modal-header">
          <h4 id="helpModalLabel">{props.cdata.name}</h4>
        </div>
        <div className="modal-body">
          <div id="helpConetent">
            <h4>{props.showDate}</h4>
            {props.slotName}
            <br />
            Time: {props.cdata.time} <br />
            Teacher: {props.cdata.teacher}
            <br />
            Course: {props.cdata.course}
            <br />
            Topic: {props.cdata.topic}
            <br />
            <button
              type="button"
              className=" btn btn-danger mt-2"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CalanderEvent;
