import React, { useContext, useEffect, useState } from "react";
import DataLoading from "../components/DataLoading";
import { UserContext } from "../components/UserContext";
import axiosInstance from "../helper/axios";
import Pager from "react-pager";
const attApi = axiosInstance;

function Notifications(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [annData, setAnnData] = useState([]);
  const [pager, setPager] = useState({ current: 0, total: 0, pageCount: 0 });
  const handlePageChanged = (newPage) => {
    getData(newPage + 1);
  };

  const getData = (page) => {
    setLoading(true);
    attApi
      .get("/getnotifications2?page=" + page)
      .then((res) => {
        if (res.status === 200) {
          setAnnData(res.data.data);
          setPager(res.data.pager);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setTitle("Notifications");
    setOperation("Notifications");
    setPLink("");
    getData(0);
  }, [setTitle, setOperation, setPLink]);

  return (
    <div>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          {pager.total === 0 ? (
            <p>No Data found</p>
          ) : (
            <p>
              Showing {(pager.current + 1) * 20 - 19}-
              {pager.total < (pager.current + 1) * 20
                ? pager.total
                : (pager.current + 1) * 20}
              of {pager.total} notifications.
            </p>
          )}
          {Object.keys(annData).map(function (key) {
            let d = annData[key];
            return (
              <div className="item" key={key}>
                <div className="ul-widget-s7__items mb-4">
                  <div className="ul-widget-s7__item-circle">
                    <p
                      className={
                        "ul-vertical-line " +
                        (key % 2 === 0 ? "bg-primary" : "bg-success")
                      }
                    ></p>
                  </div>
                  <div className="ul-widget-s7__item-text">
                    {d.created_when}
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: d.message }} />
                  </div>
                </div>
              </div>
            );
          })}
          {pager.pageCount <= 1 ? (
            <></>
          ) : (
            <Pager
              total={pager.pageCount}
              current={pager.current}
              visiblePages={10}
              titles={{ first: "First", last: "Last" }}
              className="pagination-sm"
              onPageChanged={handlePageChanged}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Notifications;
