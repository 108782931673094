import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoading from "../../components/DataLoading";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;
function Attendance(props) {
  const { setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [attData, setAttData] = useState([]);
  const [classroomData, setclassroomData] = useState([]);
  useEffect(() => {
    setLoading(true);
    setTitle("Attendance");
    setOperation("Attendance");
    setPLink("");
    attApi
      .get("/attendance")
      .then((res) => {
        if (res.status === 200) {
          setAttData(res.data);
          setclassroomData(res.data.classroomData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTitle, setOperation, setPLink]);

  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  Total {attData.classroomName}: {attData.totalClasses}
                </div>
                <div className="col-md-3">
                  Total Lectures : {attData.gTotal}
                </div>
                <div className="col-md-3">
                  Attended Lectures : {attData.gAttendance}
                </div>
                <div className="col-md-3">
                  Attended Percentage : {attData.attePer} %
                </div>
              </div>
            </div>
          </div>
          {Object.keys(classroomData)
            .reverse()
            .map(function (key) {
              return (
                <div className="card mt-2" key={key}>
                  <div className="card-body">
                    <h4>{classroomData[key].name}</h4>
                    <table className="table table-striped table-condensed table-responsive">
                      <thead>
                        <tr>
                          <th>{attData.courseName}</th>
                          <th>Teacher</th>
                          <th>Total Lectures</th>
                          <th>Attended Lectures</th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classroomData[key].data.map(function (d) {
                          return (
                            <tr key={d.id}>
                              <td>
                                <Link to={"/classroom?id=" + d.id}>
                                  {d.course +
                                    " (" +
                                    d.name +
                                    ")" +
                                    (d.section === ""
                                      ? ""
                                      : " (" + d.section + ")")}
                                </Link>
                              </td>
                              <td>{d.teacher}</td>
                              <td>{d.totalLectures}</td>
                              <td>{d.attendance}</td>
                              <td
                                className={
                                  d.totalLectures > 0 &&
                                  d.percentage < d.required
                                    ? "required"
                                    : ""
                                }
                              >
                                {d.percentage}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td>
                            <b>Total : </b>
                          </td>
                          <td>
                            <b>{classroomData[key].totalLectures}</b>
                          </td>
                          <td>
                            <b>{classroomData[key].attendance}</b>
                          </td>
                          <td>
                            <b>{classroomData[key].percentage}%</b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </>
  );
}

export default Attendance;
