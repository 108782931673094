import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";
import DataTable from "react-data-table-component";
import DataLoading from "../../components/DataLoading"

import FilterComponent from "../../components/FilterComponent";
import { Link } from "react-router-dom";

const attApi = axiosInstance;

function ClassRooms(props) {
  const columns = [
    
    {
      name: "Course",
      sortable: true,
      accessor: 'course',
      selector: ( row ) => <div className="text-wrap mt-2 mb-2"><Link to={`/classroom?id=${row.id}`}>{row.course}</Link></div>
    },
    {
      name: "Name",
      accessor: 'name',
      selector: ( row ) => <div className="text-wrap mt-2 mb-2"><Link to={`/classroom?id=${row.id}`}>{row.name}</Link></div>,
      sortable: true,
    },
    {
      name: "Semester",
      accessor: 'semester',
      selector: ( row ) => <div className="text-wrap mt-2 mb-2">{row.semester}</div>,
      sortable: true,
    },
    {
      name: "Teacher",
      accessor: 'teacher',
      selector: ( row ) => <div className="text-wrap mt-2 mb-2">{row.teacher}</div>,
      sortable: true,
    },
    {
      name: "Section",
      selector: (row) => row.section,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Use in OBE",
      selector: (row) => row.obe_include,
      sortable: true,
    },
  ];

  const customStyles2 = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize:"1em"
      },
    },
  };
  const { user, setTitle, setOperation, setPLink } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  useEffect(() => {
    setTitle("My Courses");
    setOperation("My Courses");
    setPLink("");
    setLoading(true);
    attApi.get("/classlist").then((res) => {
      if (res.status === 200) {
        let data = [];
        data = Object.keys(res.data).map(function (key) {
          return res.data[key];
        });

        setLoading(false);
        setClassData(data);
      }
    });
  }, [user, setTitle, setOperation, setPLink]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = classData.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.course.toLowerCase().includes(filterText.toLowerCase()) ||
      item.teacher.toLowerCase().includes(filterText.toLowerCase()) ||
      item.semester.toLowerCase().includes(filterText.toLowerCase()) ||
      item.status.toLowerCase().includes(filterText.toLowerCase()) ||
      item.obe_include.toLowerCase().includes(filterText.toLowerCase()) ||
      item.section.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  
  return (
    <>
      <div className="card">
        <div className="card-body">
          <span className="float-left">Total <b>{classData.length}</b> items.</span>
          <DataTable
            columns={columns}
            data={filteredItems}
            progressComponent={<DataLoading />}
            progressPending={loading}
            customStyles={customStyles2}
          //  fixedHeader
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            highlightOnHover
            striped
            subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
      </div>
    </>
  );
}

export default ClassRooms;
