import React, { useState } from "react";
import Alert from "./Alert";
function Alerts(props) {
  const alertData = props.alertData;

  const [open, setOpen] = useState(false);
  return (
    <div>
      {alertData.length === 0 ? (
        <p style={{height:"8px"}}>
          <span
            className="text-25  i-Reload mr-3 float-right"
            role="button"
            title="refresh"
            onClick={() => {
              props.refreshData();
            }}
          ></span>
        </p>
      ) : (
        <div className="accordion" id="accordionRightIcon">
          <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title ul-collapse__icon--size ul-collapse__right-icon mb-0">
                <span
                  className="text-default collapsed"
                  data-toggle="collapse"
                  onClick={() => {
                    setOpen(!open);
                  }}
                  aria-expanded="false"
                >
                  <span className="btn btn-rounded btn-danger btn-sm">
                    {alertData.length}
                  </span>
                  <span className="ml-2" role="button">
                    Important Alerts{" "}
                  </span>
                </span>
              </h6>
              <div>
                <span
                  className="text-20  i-Reload mr-3"
                  role="button"
                  title="refresh"
                  onClick={() => {
                    props.refreshData();
                  }}
                ></span>
                <span
                  role="button"
                  className={
                    "mobile-hide text-20 " +
                    (open ? "i-Arrow-Up" : "i-Arrow-Down")
                  }
                  onClick={() => {
                    setOpen(!open);
                  }}
                  title={open ? "collapse" : "expand"}
                ></span>
              </div>
            </div>
            <div
              className={"collapse " + (open ? " show" : "")}
              id="accordion-item-icons-1"
              data-parent="#accordionRightIcon"
            >
              <div className="card-body">
                {alertData.map(function (d, index) {
                  return <Alert data={d} key={index} />;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Alerts;
