import React from 'react';
import { Link } from 'react-router-dom';

function Alert(props) {
    let link = "/";
    if(props.data.type === 'survey'){
        link = "/survey?code="+props.data.survey_code;
    }else if(props.data.type === 'admit_card'){
        link = "/admitcard";
    }
    else if(props.data.type === 'assignment'){
        link = "/classroom?id="+props.data.classId;
    }
    else if(props.data.type === 'quiz'){
        link = "/quiz?id="+props.data.quizId;
    }
    else if(props.data.type === 'course_registration'){
        link = "/register";
    }
    
    return (
        <div className={"alert alert-card text-center " + props.data.alert_class} role="alert">
        {props.data.txt}
        <Link to={link} className={"btn btn-rounded ml-3 " + props.data.btn_class} >{props.data.btn_txt}</Link>
    </div>
    );
}

export default Alert;