import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataLoading from "../../components/DataLoading";

import { UserContext } from "../../components/UserContext";
import axiosInstance from "../../helper/axios";

const attApi = axiosInstance;

function Survey(props) {
  const navigate = useNavigate();
  const { setTitle, setOperation, setPLink, notify } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const sCode = searchParams.get("code");

  const [loading, setLoading] = useState(false);
  const [fill, setFill] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const [surveyData, setSurveyData] = useState([]);

  const [comment, setComment] = useState("");
  const [ansData, setAnsData] = useState({});
  const [errorArr, setErrorArr] = useState({});

  function handleChange(event) {
    setAnsData({ ...ansData, [event.target.id]: event.target.value });
    setErrorTxt("");
    setErrorArr({});
  }

  function handleCheckChange(event) {
    console.log(event.target.id);
    setAnsData({ ...ansData, [event.target.id]: event.target.value });
    setErrorTxt("");
    setErrorArr({});
  }

  function handelListChange(id, value) {
    setAnsData({ ...ansData, [id]: value });
    setErrorTxt("");
    setErrorArr({});
  }

  function saveSurvey(e) {
    e.preventDefault();
    var formData = new FormData();

    let vals = Object.keys(ansData).map(function (key) {
      return key;
    });

    if (vals.length === 0) {
      notify("Please fill in the survey!", "error");
      setErrorTxt("Please fill in the survey!");
      return;
    }

    Object.keys(ansData).map(function (key) {
      let data = ansData[key];
      let k = key.replace("answer-", "");
      return formData.append("answer[" + k + "]", data);
    });

    formData.append("comments", comment);
    attApi
      .post("/surveysubmit?code=" + sCode, formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            notify(res.data.message, "success");
            navigate("/");
          } else {
            //notify(res.data.message, "error");
            let messages = res.data.message;
            if (typeof messages === "string" || messages instanceof String) {
              notify(messages, "error");
            } else {
              setErrorArr(messages);
            }
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorTxt("Invalid arugument sent");
        } else if (err.response.status === 403) {
          setErrorTxt(err.response.data.message);
        } else {
          setErrorTxt(err.message);
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    setTitle("Survey");
    setOperation("Fill Survey");
    setPLink("");
    if (sCode !== "") {
      attApi
        .get("/getsurvey?code=" + sCode)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === false) {
              setTitle(res.data.survey_name);
              setFill(false);
              setErrorTxt(res.data.message);
            } else {
              setTitle(res.data.data.survey_name);
              setSurveyData(res.data.data);
              setFill(true);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [setTitle, setOperation, setPLink, sCode]);
  return (
    <>
      {loading ? (
        <div className="text-center">
          <DataLoading />
        </div>
      ) : (
        <>
          {fill ? (
            <>
              <div className="card">
                <div className="card-body">
                  <h3>{surveyData.course} </h3>
                  <h4>{surveyData.teacher}</h4>
                  <h4>
                    {surveyData.for === "" ? "" : "About: " + surveyData.for}
                  </h4>
                  <form>
                    <div className="form-group field-surveydata-ref has-success">
                      <label className="control-label" htmlFor="ref">
                        Comments
                      </label>
                      <input
                        type="text"
                        id="ref"
                        className="form-control"
                        name="ref"
                        maxLength="500"
                        aria-invalid="false"
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </div>
                    <p>{surveyData.notes}</p>

                    <p className="required">{errorTxt}</p>
                    {Object.keys(surveyData.questions).map(function (key) {
                      let section = surveyData.questions[key];
                      return (
                        <div className="card mt-24" key={key}>
                          <div className="card-header">
                            <h4>{key}</h4>
                          </div>
                          <div className="card-body">
                            {section.map(function (d, index) {
                              let ansContent;
                              if (d.type === "T") {
                                ansContent = (
                                  <input
                                    className="form-control"
                                    maxLength="500"
                                    type="text"
                                    name={"answer[" + d.id + "]"}
                                    id={"answer-" + d.id}
                                    value={ansData["answer-" + d.id] || ""}
                                    onChange={handleChange}
                                  />
                                );
                              } else if (d.type === "P") {
                                ansContent = (
                                  <textarea
                                    className="form-control"
                                    maxLength="900"
                                    rows="2"
                                    name={"answer[" + d.id + "]"}
                                    id={"answer-" + d.id}
                                    value={ansData["answer-" + d.id] || ""}
                                    onChange={handleChange}
                                  />
                                );
                              } else if (d.type === "N") {
                                ansContent = (
                                  <input
                                    className="form-control"
                                    type="number"
                                    name={"answer[" + d.id + "]"}
                                    id={"answer-" + d.id}
                                    value={ansData["answer-" + d.id] || ""}
                                    onChange={handleChange}
                                  />
                                );
                              } else if (d.type === "M") {
                                ansContent = (
                                  <div className="form-group">
                                    <div className="form-group field-surveydatadtl-1971-answer">
                                      <div
                                        id={"answer-" + d.id}
                                        role="radiogroup"
                                      >
                                        {Object.keys(d.choices).map(function (
                                          key2
                                        ) {
                                          let choice = d.choices[key2];
                                          return (
                                            <label
                                              key={key2}
                                              className="radio radio-primary mr-3"
                                              style={{ display: "inline" }}
                                            >
                                              <input
                                                type="radio"
                                                name={"answer[" + d.id + "]"}
                                                id={"answer-" + d.id}
                                                value={key2}
                                                onChange={handleChange}
                                              />
                                              <span>{choice}</span>
                                              <span className="checkmark"></span>
                                            </label>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (d.type === "C") {
                                ansContent = (
                                  <div className="form-group">
                                    <div className="form-group field-surveydatadtl-1971-answer">
                                      <div
                                        id={"answer-" + d.id}
                                        role="radiogroup"
                                      >
                                        {Object.keys(d.choices).map(function (
                                          key2
                                        ) {
                                          let choice = d.choices[key2];
                                          return (
                                            <label
                                              key={key2}
                                              className="checkbox radio-primary mr-3"
                                              style={{ display: "inline" }}
                                            >
                                              <input
                                                type="checkbox"
                                                name={"answer[" + d.id + "]"}
                                                value={choice}
                                                id={"answer-" + d.id}
                                                onChange={handleCheckChange}
                                              />
                                              <span>{choice}</span>
                                              <span className="checkmark"></span>
                                            </label>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (d.type === "L") {
                                let options = [];
                                options = Object.keys(d.choices).map(function (
                                  key2
                                ) {
                                  let choice = d.choices[key2];
                                  return { label: choice, value: key2 };
                                });

                                ansContent = (
                                  <Dropdown
                                    options={options}
                                    placeholder="-Select-"
                                    value={ansData["answer-" + d.id] || ""}
                                    onChange={(e) => {
                                      handelListChange(
                                        "answer-" + d.id,
                                        e.value
                                      );
                                    }}
                                  />
                                );
                              }

                              return (
                                <div key={key + index}>
                                  <h4>
                                    {d.head}. {d.question}
                                    {d.required === "1" ? (
                                      <span className="required">*</span>
                                    ) : (
                                      <></>
                                    )}
                                  </h4>
                                  <div className= {errorArr[d.id]?"form-group  has-error":"form-group"}>
                                    {ansContent}
                                    <div className="help-block">
                                      {errorArr[d.id] || ""}
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      marginBottom: "20px",
                                      marginTop: "20px",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}

                    <div className="form-group mt-12">
                      <button className="btn btn-success" onClick={saveSurvey}>
                        Save & Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="required text-17">{errorTxt}</p>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Survey;
