import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../helper/axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";

function Login(props) {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showPassword , setShowPassword] = useState(false)

  let navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  function handleUserChange(e) {
    setUsername(e.target.value);
  }
  function handlePassChange(e) {
    setPassword(e.target.value);
  }

  const loginApi = axiosInstance;

  function login(e) {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    loginApi
      .post("/authenticate", formData)
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.setItem("user", JSON.stringify(res.data));
          setUser(res.data);
        } else {
          setErrorText("Invalid username or password1!");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText("Invalid username or password1!");
        } else {
          setErrorText(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="auth-layout-wrap">
      <div className="auth-content">
        <div className="card o-hidden">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4">
                <div className="auth-logo text-center mb-4">
                  <a href="/">
                    <img src="/imgs/logo.png" alt="QOBE" />
                  </a>
                </div>
                <div className="site-login">
                  <h3>Student Login</h3>

                  <p>Please fill out the following fields to login:</p>

                  <form id="login-form" data-gtm-form-interact-id="0">
                    <input
                      type="hidden"
                      name="_csrf-backend"
                      value="MRNfdonA-Ltj9YsJwfnre48tEb55PYyzPOp3a5hZ7oJTZDMQ25mbjzrH2G6rqa053WRD3TRbteYLxy478i6NxA=="
                    />
                    <div className="form-group field-loginformstudent-username required">
                      <label htmlFor="loginformstudent-username">
                        Username
                      </label>
                      <input
                        type="text"
                        id="loginformstudent-username"
                        className="form-control is-valid"
                        name="LoginFormStudent[username]"
                        autoFocus=""
                        aria-required="true"
                        data-gtm-form-interact-field-id="0"
                        aria-invalid="false"
                        onChange={handleUserChange}
                        value={username}
                      />

                      <div className="invalid-feedback"></div>
                    </div>
                    <i className="login-eye" id="login_eye" 
                    style={{backgroundImage: showPassword?'url("/imgs/eye_close.svg")':'url("/imgs/icon-preview.svg")' }}
                    onClick={()=>{setShowPassword(!showPassword)}} ></i>
                    <div className="form-group field-loginformstudent-password required">
                      <label htmlFor="loginformstudent-password">
                        Password
                      </label>
                      <input
                        type={showPassword?"input":"password"}
                        id="loginformstudent-password"
                        className="form-control is-valid"
                        name="LoginFormStudent[password]"
                        aria-required="true"
                        data-gtm-form-interact-field-id="1"
                        aria-invalid="false"
                        onChange={handlePassChange}
                        value={password}
                      />

                      <div className="required">{errorText}</div>
                    </div>

                    <div>
                      Are you a <b>Faculty</b>?{" "}
                      <a href="https://qualityobe.com/site/login">Login Here</a>
                      <br />
                      If you forgot your password you can{" "}
                      <a href="/site/request-password-reset">reset it</a>.
                    </div>

                    <div className="form-group mt-3">
                      {loading ? (
                        <DataLoading loadTxt ="Authenticating....." />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          name="login-button"
                          onClick={login}
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
