import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 101,
  },
};

Modal.setAppElement("#root");

function TeachingPlanCard(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const columns = [
    {
      name: "File Name",
      accessor: "filename",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">
          {row.type === "V" ? (
            <span
              role="button"
              onClick={() => {
                openModal();
                setVideoName(row.filename);
                setVideoCode(row.path);
              }}
            >
              {row.filename}
            </span>
          ) : (
            <a href={row.path} target="_blank" rel="noopener noreferrer">
              {row.filename}
            </a>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Creation Date",
      accessor: "cdate",
      selector: (row) => <div className="text-wrap mt-2 mb-2">{row.cdate}</div>,
      sortable: true,
    },
    {
      name: "Description",
      accessor: "description",
      selector: (row) => (
        <div className="text-wrap mt-2 mb-2">{row.description}</div>
      ),
      sortable: true,
    },
  ];
  const [videoName, setVideoName] = useState("");
  const [videoCode, setVideoCode] = useState("");

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h4> {props.data.name}</h4>
        <div className="row">
          <div className="col-md-6">
            <b>Subject: </b> {props.data.subject}
          </div>
          <div className="col-md-3">
            <b>From :</b> {props.data.from_date}
          </div>
          <div className="col-md-3">
            <b>To :</b> {props.data.to_date}
          </div>
        </div>
        <b>Topic: </b>
        {props.data.topics  ?
          <>
            {props.data.topics.split("\n").map((item, key) => {
              return (
                <Fragment key={key}>
                  {item}
                  <br />
                </Fragment>
              );
            })}
          </>
          : <></>
        }
        <div className="row">
          <div className="col-md-6">
            <b>{props.cloName}: </b> {props.data.clos.join(", ")}
          </div>
          <div className="col-md-6">
            <b>Class Activities: :</b> {props.data.activities.join(", ")}
          </div>
        </div>
        <b>Comments: </b>
{        props.data.comments?
<>
        {props.data.comments.split("\n").map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
        </>
       :<></>
      }
        {props.data.video === "" ? (
          <></>
        ) : (
          <p className=" mb-12 mt-3">
            <iframe
              title={props.data.name}
              width="560"
              height="315"
              src={"https://www.youtube.com/embed/" + props.data.video}
              frameBorder="0"
              allow=" autoplay; encrypted-media; "
              allowFullScreen
            ></iframe>
          </p>
        )}
        {props.data.attachments.length === 0 ? (
          <></>
        ) : (
          <>
            <h4 className="mt-4">Attachments</h4>
            <DataTable
              columns={columns}
              data={props.data.attachments}
              highlightOnHover
              striped
            />

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div className="modal-header">
                <h4 id="helpModalLabel">{videoName}</h4>
              </div>
              <div className="modal-body">
                <div id="helpConetent">
                  <div style={{ width: "100%", height: "350px" }}>
                    <iframe
                      title="videoss"
                      style={{ height: "350px", width: "600px" }}
                      src={videoCode}
                      frameborder="0"
                      webkitAllowFullScreen
                      mozallowfullscreen
                      allowFullScreen
                    ></iframe>
                  </div>
                  '
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

export default TeachingPlanCard;
